import React from 'react';
import axios from 'axios';
import withLazyLoad from './LazyLoad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faBriefcase, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
// import { Helmet } from 'react-helmet';

class Careers extends React.Component {
	state = {
        backgroundImageUrl: null,
        primaryCareersImageUrl: null,
        secondaryCareersImagesUrl: []
    };

    async componentDidMount() {
    	const [backgroundImages, allJobs] = await Promise.all([
    		axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[$or][0][name][$eq]=background-city-2&filters[$or][1][name][$contains]=background-careers-`),
    		axios.get(`${process.env.REACT_APP_CMS_URL}/api/jobs`)
    	]);

    	let backgroundImageUrl;
    	let primaryCareersImageUrl;
    	let secondaryCareersImagesUrl;

        if (backgroundImages.data.length) {
        	const cityBackgroundImage = backgroundImages.data.filter(image => image.name === 'background-city-2');
        	const primaryCareersImage = backgroundImages.data.filter(image => image.name === 'background-careers-primary');
        	const secondaryCareersImages = backgroundImages.data
        		.filter(image => image.name.includes('background-careers-secondary-'))
        		.map(image => image.formats.small.url);

        	backgroundImageUrl = cityBackgroundImage[0].formats.large.url;
        	primaryCareersImageUrl = primaryCareersImage[0].formats.medium.url;
        	secondaryCareersImagesUrl = secondaryCareersImages;
        }

        let jobPosts;

        if (allJobs.data.data.length) {
        	jobPosts = allJobs.data.data;
        }

        this.setState({
        	...this.state,
        	backgroundImageUrl,
        	primaryCareersImageUrl,
        	secondaryCareersImagesUrl,
        	jobPosts
        }, () => this.props.fadeElementsIn(this.props.isVisible));

        document.title = 'Careers - Azur Technology';
    }

    numberWithCommas(x) {
    	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	render() {
	    return (
	    	<div className="careers-wrapper">
{/*	    		<Helmet defer={false}>
	    			<title>Careers - Azur Technology</title>
	    			<meta name="title" content="Careers - Azur Technology" />
		    		<meta name="description" content="Azur Technology is at the forefront of digitising the insurance sector, working with some of the biggest names in the business to bring innovative and exciting products to market. Come and have a look at our open roles." />
		    		<meta name="image" content={this.state.backgroundImageUrl} />

		    		<meta property="og:type" content="website" />
				    <meta property="og:url" content="http://www.azurtechnology.com/careers" />
				    <meta property="og:title" content="Careers - Azur Technology" />
				    <meta property="og:description" content="Azur Technology is at the forefront of digitising the insurance sector, working with some of the biggest names in the business to bring innovative and exciting products to market. Come and have a look at our open roles." />
				    <meta property="og:image" content={this.state.backgroundImageUrl} />

			        <meta property="twitter:card" content="summary_large_image" />
    				<meta property="twitter:url" content="http://www.azurtechnology.com/" />
    				<meta property="twitter:title" content="Careers - Azur Technology"/>
    				<meta property="twitter:description" content="Azur Technology is at the forefront of digitising the insurance sector, working with some of the biggest names in the business to bring innovative and exciting products to market. Come and have a look at our open roles." />
    				<meta property="twitter:image" content={this.state.backgroundImageUrl} />
		    	</Helmet>*/}
	    		<div className="careers-inner">
		    		<div className="page-subheading">Careers</div>
		    		{/*<h1 className="lazy-load">We all dare to fail, and we're all free to succeed</h1>*/}
		    		<div className="info-panel lazy-load">
		    			<div className="info-panel-child info-panel-text">
		    				<p><strong>We’re always looking for bright, curious people to join us.</strong> We're currently 25 people strong and we have clear plans to scale the business. We play in the Salesforce stack. We configure where can, but if we need to build Lightning Component or Heroku apps to drive revenues, we do. Whatever we do, we want to do it better.</p>
		    				<p>We offer you a position in an exciting technology and product-focussed business and a chance to truly be a part of something great and challenging.</p>
		    				<p>It goes without saying that you’ll be a part of a fun, friendly atmosphere, with plenty of opportunities to grow personally and professionally. You’ll get a competitive salary, unlimited holidays, and a relaxed, flexible, and hybrid work environment. We firmly believe in empowerment, career progression, and trusting our colleagues.</p>
		    				{this.state.jobPosts && <p>Come and have a look at our open roles.</p>}
		    				{!this.state.jobPosts && <p><strong>Roles go like hot cakes at Azur, and right now all our roles are filled.
		    					{/*If you would like to join us, register your interest at <a href="mailto:careers@azuruw.com">careers@azuruw.com</a>.*/}
		    					</strong></p>}
		    			</div>
		    		</div>
		    		{this.state.jobPosts && <div className="jobpost-padding"></div>}
		    		<div className="jobposts-wrapper lazy-load">
			    		{this.state.jobPosts && this.state.jobPosts.map((jobPost, i) =>
			    			<a className="jobpost-wrapper" key={i} href={'/jobs/post?id=' + jobPost.id}>
			    				<h3>{jobPost.attributes.jobTitle}</h3>
			    				<p>
			    					<span>
			    						<FontAwesomeIcon icon={faLocationDot} size="1x"/> {jobPost.attributes.location}
			    					</span>
			    					<span className="contract">
			    					{(jobPost.attributes.contract || jobPost.attributes.hours) && <FontAwesomeIcon icon={faBriefcase} size="1x"/>}
			    					{jobPost.attributes.contract && <span> {jobPost.attributes.contract}</span>}
			    					{jobPost.attributes.contract && jobPost.attributes.hours && <span>,</span>}
			    					{jobPost.attributes.hours && <span> {jobPost.attributes.hours}</span>}
			    					</span>
			    				</p>
			    				<p>
			    					{(jobPost.attributes.salaryUpper || jobPost.attributes.salaryLower) && <FontAwesomeIcon icon={faMoneyBill} size="1x"/>}
			    					{jobPost.attributes.salaryLower && <span> £{this.numberWithCommas(jobPost.attributes.salaryLower)}</span>}
			    					{!jobPost.attributes.salaryUpper && jobPost.attributes.salaryLower && <span>+</span>}
			    					{jobPost.attributes.salaryUpper && jobPost.attributes.salaryLower && <span> - </span>}
			    					{jobPost.attributes.salaryUpper && !jobPost.attributes.salaryLower && <span> Up to </span>}
			    					{jobPost.attributes.salaryUpper && <span>£{this.numberWithCommas(jobPost.attributes.salaryUpper)}</span>}
			    					{jobPost.attributes.billing && <span> {jobPost.attributes.billing}</span>}
			    				</p>
			    			</a>
			    		)}
		    		</div>
		    		{this.state.primaryCareersImageUrl && this.state.secondaryCareersImagesUrl.length && <div className="info-panel">
		    			<div className="info-panel-child one-image-panel">
		    				<div className="decorative-image" style={{ backgroundImage: `url(${this.state.primaryCareersImageUrl})` }}></div>
		    			</div>
		    			<div className="info-panel-child four-images-panel">
		    				<div className="decorative-image" style={{ backgroundImage: `url(${this.state.secondaryCareersImagesUrl[0]})` }}></div>
		    				<div className="decorative-image" style={{ backgroundImage: `url(${this.state.secondaryCareersImagesUrl[1]})` }}></div>
		    				<div className="decorative-image" style={{ backgroundImage: `url(${this.state.secondaryCareersImagesUrl[2]})` }}></div>
		    				<div className="decorative-image" style={{ backgroundImage: `url(${this.state.secondaryCareersImagesUrl[3]})` }}></div>
		    			</div>
		    		</div>}
		    		{/*<div className="info-panel">
		    			<div className="info-panel-child lazy-load">
				    		<ul>
				    			<li>BUPA cover</li>
								<li>Casual work environment</li>
								<li>Discounted gym classes</li>
								<li>Flexible working hours</li>
								<li>Health shield benefits</li>
							</ul>
						</div>
		    			<div className="info-panel-child lazy-load">
		    				<ul>
								<li>Offsite team-building events</li>
								<li>Open plan office and exposure to board</li>
								<li>Ping pong table</li>
								<li>Season ticket support</li>
							</ul>
		    			</div>
		    		</div>*/}
	    		</div>
	    		{this.state.backgroundImageUrl && <div className="bottom-photo-banner" style={{
	    			backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.state.backgroundImageUrl})`
	    		}}></div>}
	   		</div>
	    );
	}
}

export default withLazyLoad(Careers);