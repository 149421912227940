import React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faBriefcase, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';

class JobPost extends React.Component {
	state = {};

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_CMS_URL}/api/jobs/${this.props.jobId}?populate=*`)
      		.then(res => {
        		this.setState(res.data);
        		document.title = `${res.data.data.attributes.jobTitle} - Azur Technology`;
      		})
      		.catch(() => {
      			window.location.href = '/notfound';
      		});
	}

	numberWithCommas(x) {
    	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	render() {
	    return (
    		<div className="job-wrapper">
    			{ !this.state.data && <Loading />}
	    		{ this.state.data && <div className="job-body-inner">
	    				<h1>{this.state.data.attributes.jobTitle}</h1>
	    				<div className="job-details-wrapper">
		    				<p className="job-details">
		    					<span>
		    						<FontAwesomeIcon icon={faLocationDot} size="1x"/> {this.state.data.attributes.location}
		    					</span>
		    					<span className="contract">
		    					{(this.state.data.attributes.contract || this.state.data.attributes.hours) && <FontAwesomeIcon icon={faBriefcase} size="1x"/>}
		    					{this.state.data.attributes.contract && <span> {this.state.data.attributes.contract}</span>}
		    					{this.state.data.attributes.contract && this.state.data.attributes.hours && <span>,</span>}
		    					{this.state.data.attributes.hours && <span> {this.state.data.attributes.hours}</span>}
		    					</span>
		    				</p>
		    				<p className="job-details">
		    					{(this.state.data.attributes.salaryUpper || this.state.data.attributes.salaryLower) && <FontAwesomeIcon icon={faMoneyBill} size="1x"/>}
		    					{this.state.data.attributes.salaryLower && <span> £{this.numberWithCommas(this.state.data.attributes.salaryLower)}</span>}
		    					{!this.state.data.attributes.salaryUpper && this.state.data.attributes.salaryLower && <span>+</span>}
		    					{this.state.data.attributes.salaryUpper && this.state.data.attributes.salaryLower && <span> - </span>}
		    					{this.state.data.attributes.salaryUpper && !this.state.data.attributes.salaryLower && <span> Up to </span>}
		    					{this.state.data.attributes.salaryUpper && <span>£{this.numberWithCommas(this.state.data.attributes.salaryUpper)}</span>}
		    					{this.state.data.attributes.billing && <span> {this.state.data.attributes.billing}</span>}
		    				</p>
	    				</div>
	    				<ReactMarkdown>{this.state.data.attributes.body}</ReactMarkdown>
	    				<p><strong>For more information and to apply, contact us at 020 3319 8888 or info@azuruw.com.</strong></p>
	    			</div>
	    		}
    		</div>
	    );
	}
}

function withParams(Component) {
  return function WrappedComponent(props) {
    const [ searchParams ] = useSearchParams();
    return <Component {...props} jobId={searchParams.get('id')} />;
  }
}

export default withParams(JobPost);