import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faUsers, faServer, faToolbox, faQuoteLeft, faFileLines, faScaleBalanced, faCircleCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import smarthomeQuote2 from '../assets/image/screenshots/smarthome-quote-2.png';
import smarthomeQuote3 from '../assets/image/screenshots/smarthome-quote-3.png';
import smarthomeQuote4 from '../assets/image/screenshots/smarthome-quote-4.png';

import smartdocs2 from '../assets/image/screenshots/smartdocs-2.png';
import smartdocs3 from '../assets/image/screenshots/smartdocs-3.png';
import smartdocs4 from '../assets/image/screenshots/smartdocs-4.png';

import smarthomeQuotedetail1 from '../assets/image/screenshots/quotedetail-1.png';
import smarthomeQuotedetail2 from '../assets/image/screenshots/quotedetail-2.png';
import smarthomeQuotedetail3 from '../assets/image/screenshots/quotedetail-3.png';

import smarthomeDashboard from '../assets/image/screenshots/smarthome-dashboard.png';
import ScrollPrompt from './ScrollPrompt';
import withLazyLoad from './LazyLoad';

class ProductComponents extends React.Component {
    state = {
        backgroundImageUrl: null
    };

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-meeting`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })

        document.title = 'Product Components - Azur Technology';
    }

  render() {
    return (
		<div className="product-components-wrapper">
            <div className="product-components-hero">
                <div className="hero-background-square"></div>
                <div className="product-components-hero-inner">
                    <div className="page-subheading">Product Components</div>
                    <h3 className="lazy-load main-title">All our implementations take a <strong>modular approach</strong> so you can choose the components you want to include. We understand insurance and we can build to your requirements. Your product will always stand out.</h3>
                </div>
            </div>
            <div className="product-components-body">
                <div className="body-background-square"></div>
                <div className="customer-journey">
                    <div className="desktop-info-panels">
                        <div className="info-panel">
                            <div className="third-width-panel">
                                <h3>The platform enables:</h3>
                            </div>
                            <div className="two-thirds-width-panel">
                                <ul>
                                    <li>
                                        <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                        <div>Role based access across a full portal, rating, underwriting, payment and claims lifecycle within a single system</div>
                                    </li>
                                    <li>
                                        <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                        <div>Underwriter workbench to bring all broker messages, submissions, enriched risk and claims data together in one desktop view</div>
                                    </li>
                                    <li>
                                        <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                        <div>Integration with best of breed rater and data enrichment platforms</div>
                                    </li>
                                    <li>
                                        <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                        <div>Future proof your business with other Salesforce modules including CRM, Policy Administration, Tableau, Pardot and Slack</div>
                                    </li>
                                    <li>
                                        <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                        <div>Full IT security within a robust enterprise grade SaaS platform approved in multiple regulated jurisdictions</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
        		</div>
                 <div className="mobile-info-panels">
                    <div className="full-width-panel">
                        <h3>The platform enables:</h3>
                    </div>
                    <div className="full-width-panel extra-bottom-padding">
                        <div className="customer-journey">
                            <ul>
                                <li>
                                    <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                    <div>Role based access across a full portal, rating, underwriting, payment and claims lifecycle within a single system</div>
                                </li>
                                <li>
                                    <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                    <div>Underwriter workbench to bring all broker messages, submissions, enriched risk and claims data together in one desktop view</div>
                                </li>
                                <li>
                                    <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                    <div>Integration with best of breed rater and data enrichment platforms</div>
                                </li>
                                <li>
                                    <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                    <div>Future proof your business with other Salesforce modules including CRM, Policy Administration, Tableau, Pardot and Slack</div>
                                </li>
                                <li>
                                    <div className="circle-bullet"><FontAwesomeIcon icon={faCircleCheck} size="2x"/></div>
                                    <div>Full IT security within a robust enterprise grade SaaS platform approved in multiple regulated jurisdictions</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="desktop-info-panels">
                    <div className="info-panel lazy-load lazy-load-faster">
                        <div className="quarter-width-panel">
                            <div className="small-circle-1 background-secondary"></div>
                            <FontAwesomeIcon icon={faQuoteLeft} size="6x"/>
                        </div>
                        <div className="three-quarter-width-panel text-panel lazy-load">
                            <div>
                                <h4>Quotes and Policies</h4>
                                <h5>Create and administer quotes and turn them into policies with our custom quote and bind components. These are tailored to the specific insurance class and according to your requirements. Track new quotes and policies in real time and build automated referrals processes to reduce manual work.</h5>
                                <h5>Once a policy is live, easily create MTAs and cancellations with a comprehensive policy management portal. Allow your customers to self-serve or keep these processes in-house. We’ll help you figure out the best approach for your product.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-info-panels">
                    <div className="full-width-panel fixed-height image-icon">
                        <div className="background-circle small-circle-1"></div>
                        <FontAwesomeIcon icon={faQuoteLeft} size="6x"/>
                    </div>
                    <div className="full-width-panel extra-bottom-padding">
                        <div className="text-panel-mobile">
                            <h3 className="h3-mobile">Quotes and Policies</h3>
                            <p className="text-paragraph-mobile">Create and administer quotes and turn them into policies with our custom quote and bind components. These are tailored to the specific insurance class and according to your requirements. Track new quotes and policies in real time and build automated referrals processes to reduce manual work.</p>
                            <p className="text-paragraph-mobile">Once a policy is live, easily create MTAs and cancellations with a comprehensive policy management portal. Allow your customers to self-serve or keep these processes in-house. We’ll help you figure out the best approach for your product.</p>
                        </div>
                    </div>
                </div>
                <div className="screenshot-wrapper">
                    <div className="product-screenshot-wrapper">
                        <img className="product-screenshot smarthome-quote1" src={smarthomeQuote4} />
                        <img className="product-screenshot smarthome-quote2" src={smarthomeQuote3} />
                        <img className="product-screenshot smarthome-quote3" src={smarthomeQuote2} />
                        {/*<img className="product-screenshot smarthome-quote4" src={smarthomeQuote1} />*/}
                    </div>
                </div>
                <div className="desktop-info-panels">
                    <div className="info-panel lazy-load lazy-load-faster">
                        <div className="quarter-width-panel">
                            <div className="small-circle-1 background-secondary"></div>
                            <FontAwesomeIcon icon={faFileLines} size="6x"/>
                        </div>
                        <div className="three-quarter-width-panel text-panel lazy-load">
                            <div>
                                <h4>Pricing, Underwriting and Documents</h4>
                                <h5>Configure your core insurance product with a powerful ratings engine and underwriting rules that can be updated and versioned to keep your product competitive and compliant. Automation here will enable you to scale without increasing headcount significantly.</h5>
                                <h5>Create dynamic documents and ensure that all documents are in one place, including schedules, wordings, ISOs, and other regulatory forms. Our core insurance components are the crux of the insurance product, built on modern technologies for robustness and low-cost maintenance.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-info-panels">
                    <div className="full-width-panel fixed-height image-icon">
                        <div className="background-circle small-circle-1"></div>
                        <FontAwesomeIcon icon={faFileLines} size="6x"/>
                    </div>
                    <div className="full-width-panel extra-bottom-padding">
                        <div className="text-panel-mobile">
                            <h3 className="h3-mobile">Pricing, Underwriting and Documents</h3>
                            <p className="text-paragraph-mobile">Configure your core insurance product with a powerful ratings engine and underwriting rules that can be updated and versioned to keep your product competitive and compliant. Automation here will enable you to scale without increasing headcount significantly.</p>
                            <p className="text-paragraph-mobile">Create dynamic documents and ensure that all documents are in one place, including schedules, wordings, ISOs, and other regulatory forms. Our core insurance components are the crux of the insurance product, built on modern technologies for robustness and low-cost maintenance.</p>
                        </div>
                    </div>
                </div>
                <div className="screenshot-wrapper">
                    <div className="product-screenshot-wrapper">
                        <img className="product-screenshot smarthome-quote1" src={smartdocs4} />
                        <img className="product-screenshot smarthome-quote2" src={smartdocs3} />
                        <img className="product-screenshot smarthome-quote3" src={smartdocs2} />
                        {/*<img className="product-screenshot smarthome-quote4" src={smartdocs1} />*/}
                    </div>
                </div>
                <div className="desktop-info-panels">
                    <div className="info-panel lazy-load lazy-load-faster">
                        <div className="quarter-width-panel">
                            <div className="small-circle-1 background-secondary"></div>
                            <FontAwesomeIcon icon={faUsers} size="6x"/>
                        </div>
                        <div className="three-quarter-width-panel text-panel lazy-load">
                            <div>
                                <h4>Renewals and customer retention</h4>
                                <h5>Automate renewals and enhance the customer’s experience with custom referral rules, promotions and seamless processing. Encourage loyalty and use the power of Salesforce CRM to build a picture of your brokers and customers over time.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-info-panels">
                    <div className="full-width-panel fixed-height image-icon">
                        <div className="background-circle small-circle-1"></div>
                        <FontAwesomeIcon icon={faUsers} size="6x"/>
                    </div>
                    <div className="full-width-panel extra-bottom-padding">
                        <div className="text-panel-mobile">
                            <h3 className="h3-mobile">Renewals and customer retention</h3>
                            <p className="text-paragraph-mobile">Automate renewals and enhance the customer’s experience with custom referral rules, promotions and seamless processing. Encourage loyalty and use the power of Salesforce CRM to build a picture of your brokers and customers over time.</p>
                        </div>
                    </div>
                </div>
                <div className="screenshot-wrapper">
                    <div className="product-screenshot-wrapper">
                        <img className="product-screenshot smarthome-quote1" src={smarthomeQuotedetail1} />
                        <img className="product-screenshot smarthome-quote2" src={smarthomeQuotedetail2} />
                        <img className="product-screenshot smarthome-quote3" src={smarthomeQuotedetail3} />
                        {/*<img className="product-screenshot smarthome-quote4" src={smarthomeQuotedetail4} />*/}
                    </div>
                </div>
                <div className="desktop-info-panels">
                    <div className="info-panel lazy-load lazy-load-faster">
                        <div className="quarter-width-panel">
                            <div className="small-circle-1 background-secondary"></div>
                            <FontAwesomeIcon icon={faScaleBalanced} size="6x"/>
                        </div>
                        <div className="three-quarter-width-panel text-panel">
                            <div>
                                <h4>Conduct and regulatory processes</h4>
                                <h5>Build the exact reports you need to meet regulatory and compliance requirements. Audit trails allow you to see exactly what has happened on a policy, and when. Complaints management tools and processes to ensure compliance, and workflows to help your team. Security and privacy are at the heart of the product with Salesforce’s technology and the platform’s design.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mobile-info-panels">
                    <div className="full-width-panel fixed-height image-icon">
                        <div className="background-circle small-circle-1"></div>
                        <FontAwesomeIcon icon={faScaleBalanced} size="6x"/>
                    </div>
                    <div className="full-width-panel extra-bottom-padding">
                        <div className="text-panel-mobile">
                            <h3 className="h3-mobile">Conduct and regulatory processes</h3>
                            <p className="text-paragraph-mobile">Build the exact reports you need to meet regulatory and compliance requirements. Audit trails allow you to see exactly what has happened on a policy, and when. Complaints management tools and processes to ensure compliance, and workflows to help your team. Security and privacy are at the heart of the product with Salesforce’s technology and the platform’s design.</p>
                        </div>
                    </div>
                </div>
{/*                <div className="screenshot-wrapper">
                    <div className="decorative-circle-wrapper">
                        <div className="screenshot-background-circle-3"></div>
                        <div className="screenshot-background-circle-4"></div>
                    </div>
                    <img className="product-screenshot lazy-load lazy-load-faster" src={smarthomeDashboard} />
                </div>*/}
            </div>
            {this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                <h3>Think we'll be a good fit for you?</h3>
                <a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
            </div>}
    	</div>
    );
  }
}

export default withLazyLoad(ProductComponents);