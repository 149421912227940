import React from 'react';
import axios from 'axios';
import withLazyLoad from './LazyLoad';
import emailjs from '@emailjs/browser';
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

class ContactUs extends React.Component {
	state = {
        backgroundImageUrl: null,
        isLoadingContactForm: false,
        contactFormCompleted: false,
        contactFormError: false,
        formValidity: {
        	firstNameValid: true,
        	emailValid: true,
        	messageValid: true
        }
    };

    constructor(props) {
    	super(props);
    	this.contactForm = React.createRef();
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-city-1`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })

        document.title = 'Contact us - Azur Technology';

        // setInterval(() => {
        // 	if (document.querySelector('iframe').contentWindow.document.querySelector('label')) {
        // 		document.querySelector('iframe').contentWindow.document.querySelector('div').style.color = 'red';
        // 	}
        // }, 100);
    }

    sendEmail = e => {
    	e.preventDefault();

    	const firstNameValid = !!this.contactForm.current.from_name.value;
    	const emailValid = this.contactForm.current.from_email.value.includes('@') && this.contactForm.current.from_email.value.includes('.');
    	const messageValid = !!this.contactForm.current.message.value;

    	if (firstNameValid && emailValid && messageValid) {
			this.setState({ ...this.state, isLoadingContactForm: true }, () => {
				// setTimeout(() => {
		  //   		this.setState({
		  //   			...this.state,
		  //   			isLoadingContactForm: false,
		  //   			contactFormCompleted: true
		  //   		});
		  //   	}, 1000);

		    	// setTimeout(() => {
		    	// 	this.setState({
		    	// 		...this.state,
		    	// 		isLoadingContactForm: false,
		    	// 		contactFormError: true
		    	// 	});
		    	// }, 1000);
			});
    	} else {
    		this.setState({ ...this.state, formValidity: {
    			firstNameValid, emailValid, messageValid
    		}});
    	}

		
    	if (firstNameValid && emailValid && messageValid) {
    		this.setState({ ...this.state, isLoadingContactForm: true });

	    	emailjs.sendForm(
	    		process.env.REACT_APP_EMAILJS_SERVICE_ID,
	    		process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
	    		this.contactForm.current,
	    		process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    		)
    		.then((result) => {
    			this.setState({
		    		...this.state,
		   			isLoadingContactForm: false,
		   			contactFormCompleted: true
		    	});
    		}, (error) => {
    			this.setState({
		    		...this.state,
		   			isLoadingContactForm: false,
		   			contactFormError: true
		   		});
    		});
    	} else {
    		this.setState({ ...this.state, formValidity: {
    			firstNameValid, emailValid, messageValid
    		}});
    	}
    	
    }

	render() {
    	return (
    		<div className="contact-wrapper">
	    		<div className="contact-inner lazy-load">
	    			<div className="page-subheading">Contact us</div>
	    			<h1>Get in touch or request a demo</h1>
	    			<div className="contact-panel">
	    				<div className="contact-panel-inner">
	    					<p>We work with customers all over the world and across both personal and commercial lines. Fill in the form below to arrange a demo or a chat with one of our team.</p>
	    					<iframe src="https://www2.azurtechnology.com/l/1040143/2023-10-10/3lzy98" width="100%" height="700" type="text/html"></iframe>
	    					{/*
	    					{this.state.isLoadingContactForm && <Loading spinnerColor="scooter" lessPadding={true}/>}
	    					{!this.state.isLoadingContactForm
	    						&& !this.state.contactFormCompleted
	    						&& !this.state.contactFormError
	    						&& <form ref={this.contactForm} onSubmit={this.sendEmail}>
			    				<label>Full Name <span className="required-asterisk">*</span></label>
			    				<div className="input-and-warning-wrapper">
				    				<input type="text" name="from_name" />
				    				{!this.state.formValidity.firstNameValid && <div className="warning-message">This field is required</div>}
			    				</div>
			    				<label>Email <span className="required-asterisk">*</span></label>
			    				<div className="input-and-warning-wrapper">
			    					<input type="text" name="from_email" />
			    					{!this.state.formValidity.emailValid && <div className="warning-message">Please enter a valid email address</div>}
			    				</div>
			    				<label>Message <span className="required-asterisk">*</span></label>
			    				<div className="input-and-warning-wrapper">
			    					<textarea name="message" />
			    					{!this.state.formValidity.messageValid && <div className="warning-message">This field is required</div>}
			    				</div>
			    				<label>Your Region <span className="required-asterisk">*</span></label>
			    				<div className="input-and-warning-wrapper">
			    					<select defaultValue="UK" name="region">
										<option value="UK">UK</option>
										<option value="US">US</option>
										<option value="Europe">Europe</option>
										<option value="Other">Other</option>
									</select>
			    				</div>
			    				<input type="submit" value="Send" />
			    			</form>}
			    			{this.state.contactFormCompleted &&
			    				<div className="email-confirmation-wrapper">
			    					<div className="contact-us-checkbox">
			    						<FontAwesomeIcon icon={faCircleCheck} size="6x"/>
			    					</div>
			    					<h3>Thank you for your message</h3>
			    					<h5>Your inquiry has been submitted</h5>
			    				</div>
			    			}
			    			{this.state.contactFormError &&
			    				<div className="email-confirmation-wrapper">
			    					<div className="contact-us-error-graphic">
			    						<FontAwesomeIcon icon={faCircleExclamation} size="6x"/>
			    					</div>
			    					<h3>Oh, snap!</h3>
			    					<h5>Sorry, an error has occurred. We’re 100% dedicated to putting things right - please try again later!</h5>
			    				</div>
			    			}
			    			*/}
	    				</div>
{/*	    				<div className="contact-panel-inner">
	    					<div className="contact-method">
	    						<h2>Email</h2>
	    						<a>info@azuruw.com</a>
	    					</div>
	    					<div className="contact-method">
	    						<h2>Phone</h2>
	    						<a>020 3319 8888</a>
	    					</div>
	    				</div>*/}
	    			</div>
	    		</div>
	    		{this.state.backgroundImageUrl && <div className="bottom-photo-banner" style={{
	    			backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.state.backgroundImageUrl})`
	    		}}></div>}
    		</div>
    	);
  	}
}

export default withLazyLoad(ContactUs);