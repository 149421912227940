import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFaceDizzy } from '@fortawesome/free-solid-svg-icons';
import withLazyLoad from './LazyLoad';

class NotFound extends React.Component {
	componentDidMount() {
		document.title = 'Page not found - Azur Technology';
	}

	render() {
	    return (
	    	<div className="notfound-wrapper">
	    		<div className="notfound-inner lazy-load desktop-info-panels">
	   				<div className="half-width-panel">
	   					<div className="text-panel-wrapper">
	   						<h1>Whoops!</h1>
	    					<p>Page not found. We’re 100% dedicated to putting things right without hold-ups or hassle.</p>
	    					<a href="/">Head on back to our homepage <FontAwesomeIcon icon={faArrowRight} size="1x" /></a>
	   					</div>
	   				</div>
	   				<div className="half-width-panel">
	   					<div className="display-icon-wrapper"><FontAwesomeIcon icon={faFaceDizzy} size="10x" /></div>
	   				</div>
	    		</div>
	   		</div>
	    );
	}
}

export default withLazyLoad(NotFound);