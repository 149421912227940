import React from 'react';
import axios from 'axios';
import withLazyLoad from './LazyLoad';
import Loading from './Loading';

class Blog extends React.Component {
	state = {
		carouselCurrentlySelectedIndex: 0,
		carouselInterval: null,
		carouselFadeTimeout: null
	};

	async componentDidMount() {
      	const [allBlogPosts, carouselBlogPosts, backgroundImage] = await Promise.all([
		    axios.get(`${process.env.REACT_APP_CMS_URL}/api/blogposts?populate=*&pagination[pageSize]=6&pagination[page]=1&sort=CreatedDate%3Adesc`),
		    axios.get(`${process.env.REACT_APP_CMS_URL}/api/blogposts?filters[displayInCarousel][$eq]=true&populate=coverImage&populate=author&sort=CreatedDate%3Adesc`),
		    axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-city-3`)
		]);

		const carouselInterval = this.state.carouselInterval
			? this.state.carouselInterval
			: window.setInterval(this.handleCarouselSlideshow, 5000);

		const backgroundImageUrl = backgroundImage.data.length
            ? backgroundImage.data[0].formats.large.url
            : null;

		this.setState({
			...this.state,
			...allBlogPosts.data,
			carouselSlides: carouselBlogPosts.data.data,
			carouselInterval,
			backgroundImageUrl
		}, () => {
			return this.state.carouselSlides.length
				? document.getElementsByTagName('button')[this.state.carouselCurrentlySelectedIndex].classList.add('active')
				: null;
		});

		document.title = 'Blog - Azur Technology';
	}

	getPages() {
	    let pages = [];
	    for (let i = 0; i < this.state.meta.pagination.pageCount; i++) {
	    	if (this.state.meta.pagination.page === i + 1) {
	    		pages.push(<div key={i} onClick={this.handlePageClick} data-pagenumber={i + 1} className="pagination-circle active">{i + 1}</div>);
	    	} else {
	    		pages.push(<div key={i} onClick={this.handlePageClick} data-pagenumber={i + 1} className="pagination-circle">{i + 1}</div>);
	    	}
	    }
	    return pages;
  	};

  	handleCarouselSlideshow = () => {
  		if (this.state.carouselSlides.length <= 1) {
  			return;
  		}

  		const newSlideshowIncrement = this.state.carouselCurrentlySelectedIndex >= this.state.carouselSlides.length - 1
  			? 0 : this.state.carouselCurrentlySelectedIndex+1;

  		this.setState({ ...this.state, carouselCurrentlySelectedIndex: newSlideshowIncrement }, () => {
	  		this.setButtonToCurrentSlide(this.state.carouselCurrentlySelectedIndex);
  		});
  	}

  	handlePageClick = e => {
  		axios.get(`${process.env.REACT_APP_CMS_URL}/api/blogposts?populate=*&pagination[pageSize]=6&pagination[page]=${e.target.dataset.pagenumber}&sort=CreatedDate%3Adesc`)
      		.then(res => {
        		this.setState(res.data);
        		window.scrollTo(0, 0);
      		});
  	}

  	handleCarouselButtonClick = e => {
  		this.setButtonToCurrentSlide(e.target.dataset.index);
  		this.setState({...this.state, carouselCurrentlySelectedIndex: e.target.dataset.index});
  		window.clearInterval(this.state.carouselInterval);
  	}

  	setButtonToCurrentSlide = index => {
  		[...document.getElementsByTagName('button')].forEach(button => {
  			button.classList.remove('active');
  		});
  		document.getElementsByTagName('button')[index].classList.add('active');

  		const carouselFadeTimeout = window.setTimeout(() => {
  			document.getElementsByClassName('carousel-slide')[0].classList.remove('fade-in');
  		}, 1000);

  		this.setState({...this.state, carouselFadeTimeout }, () => {
  			document.getElementsByClassName('carousel-slide')[0].classList.add('fade-in');
  		});
  	}

	render() {
	    return (
	    	<div className="blog-wrapper">
	    		{ !this.state.data && <Loading />}
	    		<div className="carousel-wrapper lazy-load lazy-load-faster lazy-load-active">
		    		{this.state.carouselSlides && this.state.carouselSlides.length && <div className="carousel">
			    		<a className="carousel-slides-wrapper" href={'/blog/post?id=' + this.state.carouselSlides[this.state.carouselCurrentlySelectedIndex].id}>
			    			<div className="carousel-slide" style={{ backgroundImage: `url(${this.state.carouselSlides[this.state.carouselCurrentlySelectedIndex].attributes.coverImage.data.attributes.formats.large.url}` }}>
			    				<div className="carousel-text-wrapper">
			    					<h1>{this.state.carouselSlides[this.state.carouselCurrentlySelectedIndex].attributes.Title}</h1>
			    					<h2>by <strong>{this.state.carouselSlides[this.state.carouselCurrentlySelectedIndex].attributes.author.data.attributes.Name}</strong></h2>
			    				</div>
			    			</div>
		    			</a>
		    			<div className="buttons-wrapper">
		    				{this.state.carouselSlides.map((blogpost, i) => <button key={i} data-index={i} onClick={this.handleCarouselButtonClick}></button>)}
		    			</div>
		    		</div>}
	    		</div>
	    		<div className="blog-inner">
	    			<div className="blog-menu-wrapper lazy-load lazy-load-faster lazy-load-active">
	    			{ this.state.data && this.state.data.map((blogpost, i) =>
	    				<a className="card" href={'/blog/post?id=' + blogpost.id} key={i}>
	    					<div className="card-cover-image" style={{ backgroundImage: `url(${blogpost.attributes.coverImage.data.attributes.formats.small.url})` }}></div>
		    				<div className="card-category">{blogpost.attributes.category && blogpost.attributes.category.data && blogpost.attributes.category.data.attributes.Name}</div>
		    				<div className="card-title">{blogpost.attributes.Title}</div>
		    				<div>{blogpost.attributes.CreatedDate}</div>
		    				<div className="card-author">by <strong>{blogpost.attributes.author && blogpost.attributes.author.data.attributes.Name}</strong></div>
		    			</a>)
		    		}
		    		</div>
		    		{ this.state.data && <div className="page-nav">
		    			{this.getPages()}
		    			</div>
		    		}
	    		</div>
	    		{this.state.backgroundImageUrl && <div className="bottom-photo-banner" style={{
	    			backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.state.backgroundImageUrl})`
	    		}}></div>}
	   		</div>
	    );
	}
}

export default withLazyLoad(Blog);