import React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useSearchParams } from "react-router-dom";
import Loading from './Loading';

class BlogPost extends React.Component {
	state = {};

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_CMS_URL}/api/blogposts/${this.props.myHookValue}?populate=*`)
      		.then(res => {
        		this.setState(res.data);
        		document.title = `${res.data.data.attributes.Title} - Azur Technology`;
      		})
      		.catch(() => {
      			window.location.href = '/notfound';
      		});
	}

	render() {
	    return (
	    	<div>
    			{ this.state.data && 
    				<div className="blogpost-hero lazy-load lazy-load-faster lazy-load-active" style={{ backgroundImage: `url(${this.state.data.attributes.coverImage.data.attributes.formats.large.url})` }}>
    					<div className="hero-gradient-backdrop"></div>
	    				<div className="blogpost-hero-inner">
	    					<p className="blogpost-hero-category">{this.state.data.attributes.category.data.attributes.Name}</p>
		    				<h1>{this.state.data.attributes.Title}</h1>
		    				<p className="blogpost-hero-author">by <strong>{this.state.data.attributes.author.data.attributes.Name}</strong></p>
		    			</div>
		    		</div>
    			}
	    		<div className="blogpost-body lazy-load lazy-load-faster lazy-load-active">
	    			{ !this.state.data && <Loading />}
		    		{ this.state.data && <div className="blogpost-body-inner">
		    				<ReactMarkdown>{this.state.data.attributes.Body}</ReactMarkdown>
		    			</div>
		    		}
	    		</div>
	   		</div>
	    );
	}
}

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const [ searchParams ] = useSearchParams();
    return <Component {...props} myHookValue={searchParams.get('id')} />;
  }
}

export default withMyHook(BlogPost);