import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimneyMedical, faHandshakeSimple, faShieldHeart, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ScrollPrompt from './ScrollPrompt';
import axios from 'axios';
import withLazyLoad from './LazyLoad';

import Lottie from 'lottie-react';
import cloudAnimation from '../assets/lottie/cloud.json';
import mdaAnimation from '../assets/lottie/mda.json';
import passionAnimation from '../assets/lottie/passion.json';
import componentsAnimation from '../assets/lottie/components.json';
import stackAnimation from '../assets/lottie/stack.json';

import stableLogo from '../assets/image/customer-logos/stable-logo.png';
import aigLogo from '../assets/image/customer-logos/aig-logo.png';
import elmsureLogo from '../assets/image/customer-logos/elmsure-logo-white.png';
import movinxLogo from '../assets/image/customer-logos/movinx-logo-white.png';
import starfishLogo from '../assets/image/customer-logos/starfish-logo-white.png';
import salesforceLogo from '../assets/image/customer-logos/salesforce-logo-white.png';
import pwcLogo from '../assets/image/customer-logos/pwc-logo-white.png';
import zurichLogo from '../assets/image/customer-logos/zurich-logo-white.png';

import productScreenshot from '../assets/image/screenshots/smarthome-dashboard.png';

// import starfishPdf from '../assets/pdf/starfish.pdf';

class Home extends React.Component {
	state = {
		backgroundImageUrl: null
	};

	async componentDidMount() {

		const [starfishCeo, backgroundImage] = await Promise.all([
            axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=starfish-ceo`),
            axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-london`)
        ]);

        console.log(starfishCeo);

        const starfishCeoUrl = starfishCeo.data.length
            ? starfishCeo.data[0].formats.large.url
            : null;

        console.log(starfishCeoUrl);

        const backgroundImageUrl = starfishCeo.data.length
            ? backgroundImage.data[0].formats.large.url
            : null;

        this.setState({
        	starfishCeoUrl, backgroundImageUrl
        });

		document.title = 'Home - Azur Technology';
	}

	handleBookADemo() {
		window.location.href = '/contactus';
	}

	render() {
	    return (
	    	<div className="home-page-wrapper">
	    		<div className="hero-wrapper hero-wrapper-secondary">
	    			<div className="decorative-circle circle-1 lazy-load lazy-load-vertically-center"></div>
	    			<div className="decorative-circle circle-2 lazy-load lazy-load-vertically-center"></div>
	    			<div className="decorative-circle circle-3 lazy-load lazy-load-vertically-center"></div>
		    		<div className="hero hero-full-height">
		    			<div className="hero-body">
			    			<div className="hero-text">
			    				<h1 className="lazy-load"><strong>Digital Insurance Solutions</strong></h1>
			    			    <h1 className="lazy-load">Azur is a specialist technology partner for the Insurance industry. We build and operate award-winning digital insurance products for many of the world’s most ambitious and successful MGAs.</h1>
			    			    <button className="lazy-load" onClick={this.handleBookADemo}>BOOK A DEMO</button>
			    			</div>
			    			<div className="hero-image">
								<Lottie animationData={cloudAnimation} loop={true} />
			    			</div>
		    			</div>
		    			<div className="hero-bottom">
		    				<ScrollPrompt />
		    			</div>
		    		</div>
	    		</div>
	    		<div className="video-wrapper hero-wrapper hero-wrapper-oxford">
	    			<div className="header-background-stripe"></div>
	    			<iframe src="https://www.youtube.com/embed/uU9kdGxjaR4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	    		</div>
    			<div className="hero-wrapper hero-wrapper-secondary">
    				<div className="decorative-circle circle-4 lazy-load lazy-load-vertically-center"></div>
    				<div className="decorative-circle circle-5 lazy-load lazy-load-vertically-center"></div>
	    			<div className="hero hero-less-height">
    					<div className="info-panel">
			    			<div className="full-width-panel lazy-load">
			    				<div className="text-panel">
			    					<h3><strong>The Platform</strong></h3>
			    					<h4 className="lazy-load">Everything you expect to build, manage and sell insurance products, plus the latest in <strong>automation</strong> and <strong>AI</strong> technology to turbo-charge <strong>efficiency</strong> and <strong>margin</strong>.</h4>
			    					<div className="columns">
			    						<div className="small-margin-column">
				    						<ul>
						    					<li>• Submissions &amp; Quotes</li>
						    					<li>• Policy Admin</li>
						    					<li>• Billing &amp; Accounting</li>
						    					<li>• Renewals</li>
						    					<li>• Claims</li>
						    					<li>• Customized Reporting</li>
						    					<li>• Broker Portal</li>
						    				</ul>
					    				</div>
					    				<div className="small-margin-column">
						    				<ul>
						    					<li><strong>PLUS</strong></li>
						    					<li>• AI-driven Insights</li>
						    					<li>• Augmented Underwriting</li>
						    					<li>• Automated Underwriting</li>
						    				</ul>
					    				</div>
			    					</div>
			    				</div>
			    			</div>
			    		</div>
	    			</div>
    			</div>
    			<div className="hero-wrapper hero-wrapper-oxford">
    				<div className="hero hero-less-height">
			    		<div className="info-panel">
			    			<div className="full-width-panel lazy-load">
			    				<div className="text-panel">
			    					<h3 className="lazy-load">Use technology to become faster, smarter and more profitable.</h3>
			    					<div className="columns">
			    						<div className="one-third-width-column">
			    							<div className="decorative-circle content-circle lazy-load lazy-load-vertically-center">
			    								<div className="content-circle-group">
			    									<p className="center-align">Increase<br/>submissions by</p>
			    									<h3 className="center-align"><strong>75%</strong></h3>
			    								</div>
			    							</div>
					    				</div>
					    				<div className="one-third-width-column">
					    					<div className="decorative-circle content-circle lazy-load lazy-load-vertically-center">
					    						<div className="content-circle-group">
						    						<p className="center-align">Increase<br/>UW efficiency by</p>
						    						<h3 className="center-align"><strong>68%</strong></h3>
						    					</div>
					    					</div>
					    				</div>
					    				<div className="one-third-width-column">
					    					<div className="decorative-circle content-circle lazy-load lazy-load-vertically-center">
					    						<div className="content-circle-group">
					    							<p className="center-align">Double<br/>net margin to</p>
						    						<h3 className="center-align"><strong>40%</strong></h3>
						    					</div>
					    					</div>
					    				</div>
			    					</div>
			    				</div>
			    			</div>
		    			</div>
	    			</div>
	    		</div>
	    		<div className="hero-wrapper hero-wrapper-secondary">
	    			<div className="info-panel-background-stripe"></div>
    				<div className="hero hero-less-height">
			    		<div className="info-panel">
			    			<h3 className="lazy-load center-align"><strong>Working with</strong></h3>
			    			<div className="logos-section">
					    		<div className="logos-wrapper lazy-load">
					    			<div className="lesser-width"><img src={aigLogo} /></div>
					    			<div><img src={stableLogo} /></div>
						    		<div><img src={elmsureLogo} /></div>
						    		<div><img src={movinxLogo} /></div>
						    		<div><img src={starfishLogo} /></div>
						    		<div className="lesser-width"><img src={salesforceLogo} /></div>
						    		<div className="lesser-width"><img src={pwcLogo} /></div>
						    		<div><img src={zurichLogo} /></div>
					    		</div>
					    	</div>
		    			</div>
	    			</div>
	    		</div>
	    		<div className="hero-wrapper hero-wrapper-oxford">
    				<div className="info-panel-background-stripe-2"></div>
    				<div className="hero hero-less-height">
			    		<div className="info-panel">
			    			<div className="half-width-panel lazy-load">
			    				<div className="text-panel">
			    					<div>
			    						{this.state.starfishCeoUrl && <div className="testimony-headshot" style={{ backgroundImage: `url(${this.state.starfishCeoUrl})` }}></div>}
			    					</div>
			    				</div>
			    			</div>
			    			<div className="half-width-panel lazy-load">
			    				<div className="text-panel">
			    					<h4 className="lazy-load"><strong>&quot;Azur’s ability to build and launch complex, market leading insurance products in timeframes of just a few months is truly what makes them stand out from the competition&quot;</strong></h4>
			    					<h4 className="lazy-load"><em>– Jeremy Hitzig CEO, Starfish Specialty Insurance</em></h4>
			    					<img className="testimony-logo" src={starfishLogo} />
			    					<div className="lazy-load">
				    					<a className="pdf-url-link" href="https://www2.azurtechnology.com/l/1040143/2023-10-23/3m1rjg/1040143/1698051577ZZ8Aho2y/Starfish_Case_Study.pdf" target="_blank">
		                                    <button className="pdf-url-button">
		                                        <div><FontAwesomeIcon icon={faFilePdf} size="2x" /></div>
		                                        View case study
		                                    </button>
		                                </a>
	                                </div>
			    				</div>
			    			</div>
		    			</div>
	    			</div>
	    		</div>
	    		<div className="hero-wrapper hero-wrapper-secondary">
		    		{this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
		    			<h3>Like the sound of this?</h3>
		    			<a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
		    		</div>}
	    		</div>
	   		</div>
	    );
	}
}

export default withLazyLoad(Home);