import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import withLazyLoad from './LazyLoad';
import azurlogo from '../assets/image/azur-logo.png';

class Maintenance extends React.Component {
	componentDidMount() {
		document.title = 'Coming soon - Azur Technology';
	}

	render() {
	    return (
	    	<div className="notfound-wrapper maintenance-wrapper">
	    		<div className="notfound-inner lazy-load desktop-info-panels">
	   				<div className="half-width-panel">
	   					<div className="text-panel-wrapper">
	   						<img className="logo-image" src={azurlogo} />
	   						<h1>Coming soon</h1>
	    					<p>We're hard at work behind the scenes creating our amazing new website!</p>
	   					</div>
	   				</div>
	   				<div className="half-width-panel">
	   					<div className="display-icon-wrapper maintenance-icon"><FontAwesomeIcon icon={faWrench} size="10x" /></div>
	   				</div>
	    		</div>
	   		</div>
	    );
	}
}

export default withLazyLoad(Maintenance);