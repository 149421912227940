import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faFileContract, faQuoteLeft, faQuoteRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import withLazyLoad from './LazyLoad';
import pdfFileAscot from '../assets/pdf/celent-ascot-array.pdf';
import pdfFileSmart from '../assets/pdf/celent-smart-home.pdf';

class WhoBrokers extends React.Component {
	state = {
        backgroundImageUrl: null
    };

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-buildings`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })

        document.title = 'Brokers - Azur Technology';
    }

	 render() {
	    return (
	    	<div className="who-wrapper">
	    		<div className="who-inner">
		            <div className="page-subheading">Brokers</div>
	    			<h3 className="lazy-load"><strong>Brokers</strong> - Innovate and Grow</h3>
	    			<h4 className="lazy-load">Spend more time providing expert advice to your customers and less time on data entry with our smart product implementations.</h4>
		            <div className="desktop-info-panels">
				    	<div className="info-panel lazy-load">
			                <div className="half-width-panel">
			                    <div className="small-square-1 background-secondary skew-right-more"></div>
			                    <FontAwesomeIcon icon={faFileContract} size="10x"/>
			                </div>
			                <div className="half-width-panel text-panel">
			                    <div>
									<h5>We have a deep focus on User Experience to ensure the best possible customer journey.</h5>
									<h5>We believe in creating a true partnership to deliver and an ongoing relationship to ensure your platform is maintained to the highest standard.</h5>
			                    </div>
			                </div>
			            </div>
		            </div>
		            <div className="mobile-info-panels">
		            	<div className="full-width-panel fixed-height image-icon">
	                    	<div className="background-square small-square-1"></div>
	                    	<FontAwesomeIcon icon={faFileContract} size="6x"/>
	                	</div>
	                	<div className="full-width-panel">
	                    	<div className="text-panel-mobile">
	                        	<p className="text-paragraph-mobile">We have a deep focus on User Experience to ensure the best possible customer journey.</p>
	                        	<p className="text-paragraph-mobile">We’re not just technology platform, we’re an underwriting company that cares about the success of our partnership.</p>
	                    	</div>
	                	</div>
		            </div>
{/*		           	<div className="quote-wrapper lazy-load">
		           		<div className="mobile-quote-mark mobile-open-quote"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></div>
		                <h4><span className="quotation-mark"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></span>Insert an even longer quote here<span className="quotation-mark"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></span></h4>
		                <h4>that goes onto multiple lines</h4>
		                <div className="mobile-quote-mark mobile-closed-quote"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></div>
		                <h5>- Elmsure</h5>
		            </div>*/}
            	</div>
        		{this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                	<h3>Ready to be our next success story?</h3>
                	<a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
            	</div>}
	    	</div>
	    );
	}
}

export default withLazyLoad(WhoBrokers);