import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Loading extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.spinnerColor === 'scooter') {
			document.getElementsByClassName('loading-wrapper')[0].classList.add('scooter-color');
		}
	}

	render() {
	    return (
	    	<div className={this.props.lessPadding ? 'loading-wrapper less-padding' : 'loading-wrapper'}>
	    		<FontAwesomeIcon icon={faSpinner} size="4x"/>
	   		</div>
	    );
	}
}

export default Loading;