import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faQuoteLeft, faQuoteRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import withLazyLoad from './LazyLoad';

class WhoMGA extends React.Component {
	state = {
        backgroundImageUrl: null
    };

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-buildings`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })

        document.title = 'MGA - Azur Technology';
    }

	 render() {
	    return (
	    	<div className="who-wrapper">
	    		<div className="who-inner">
	    			<div className="page-subheading">MGA</div>
	    			<h3 className="lazy-load"><strong>MGAs</strong> - MGA-in-a-box</h3>
	    			<h4 className="lazy-load">Launch your MGA’s first or next product on a <strong>scalable insurance platform</strong> built to prioritise <strong>speed to market</strong> and <strong>robust design</strong>.</h4>
		    		<div className="desktop-info-panels">
				    	<div className="info-panel lazy-load">
			                <div className="half-width-panel">
			                    <div className="small-square-1 skew-right"></div>
			                    <FontAwesomeIcon icon={faBoxOpen} size="10x"/>
			                </div>
			                <div className="half-width-panel text-panel">
			                    <div>
									<h5>We provide a configurable set of insurance tools, easy to manage customer journeys and expert technical advice on Finance, UW, Marketing, Risk and Tech Ops.</h5>
									<h5>With our solution all your reports and data will be in one place, allowing you to see a comprehensive picture of your customer and policies.</h5>
									<h5>This all comes with an expert embedded product team, to help you implement your product.</h5>
			                    </div>
			                </div>
			            </div>
		            </div>
		            <div className="mobile-info-panels">
		            	<div className="full-width-panel fixed-height image-icon">
	                    	<div className="background-square small-square-2"></div>
	                    	<FontAwesomeIcon icon={faBoxOpen} size="6x"/>
	                	</div>
	                	<div className="full-width-panel">
	                    	<div className="text-panel-mobile">
	                        	<p className="text-paragraph-mobile">We provide a configurable set of insurance tools, easy to manage customer journeys and expert technical advice on Finance, UW, Marketing, Risk and Tech Ops.</p>
	                        	<p className="text-paragraph-mobile">With our solution all your reports and data will be in one place, allowing you to see a comprehensive picture of your customer and policies.</p>
	                        	<p className="text-paragraph-mobile">This all comes with an expert embedded product team, to help you implement your product.</p>
	                    	</div>
	                	</div>
		            </div>
{/*			        <div className="quote-wrapper lazy-load">
			        	<div className="mobile-quote-mark mobile-open-quote"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></div>
		                <h4><span className="quotation-mark"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></span>Insert a quote here<span className="quotation-mark"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></span></h4>
		                <div className="mobile-quote-mark mobile-closed-quote"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></div>
		                <h5>- Azur PCG</h5>
		            </div>*/}
            	</div>
        		{this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                	<h3>Ready to be our next success story?</h3>
                	<a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
            	</div>}
	    	</div>
	    );
	}
}

export default withLazyLoad(WhoMGA);