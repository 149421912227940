import React from 'react';

function withLazyLoad(Component) {
	return class extends React.Component {
		constructor(props) {
			super(props);
		}

		componentDidMount() {
			this.fadeElementsIn(this.isVisible);

			document.addEventListener('scroll', () => {
				this.fadeElementsIn(this.isVisible);
			});
		}

		isVisible(e) {
			const rect = e.getBoundingClientRect();

		    return (
		        // rect.top >= 0 &&
		        rect.left >= 0 &&
		        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + (rect.bottom - rect.top)/2 &&
		        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		    );
		}

		fadeElementsIn(isVisible) {
			const elementsToFade = [...document.getElementsByClassName('lazy-load')];

			elementsToFade.forEach(e => {
				if (isVisible(e)) {
					e.classList.add('lazy-load-active');
				}
			});
		}

		render() {
			return <Component
				fadeElementsIn={this.fadeElementsIn}
				isVisible={this.isVisible}
				{...this.props}
			/>;
		}
	}
}

export default withLazyLoad;