import React from 'react';
import { Link } from 'react-router-dom';
import azurlogodark from '../assets/image/azur-logo-dark.png';
import salesforcePartnerLogo from '../assets/image/salesforce-partner-logo.png';
import privacyPolicyLink from '../assets/pdf/privacy-policy.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import starfishPdf from '../assets/pdf/starfish.pdf';

class Footer extends React.Component {
    state = {
        whatDropdownOpen: false,
        whoDropdownOpen: false
    }

    toggleDropdown = dropdownName => {
        const stateToSet = {...this.state};

        switch(dropdownName) {
            case 'what':
                stateToSet.whatDropdownOpen = !this.state.whatDropdownOpen;
                break;
            case 'who':
                stateToSet.whoDropdownOpen = !this.state.whoDropdownOpen;
                break;
            }

        this.setState(stateToSet);
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
        	<footer style={{ borderBottom: '10px solid #F77E84' }}>
        		<div className="footer-wrapper">
        			<div className="two-columns">
        				<div className="left-panel">
        					<img className="azur-logo" src={azurlogodark} />
                            <img className="salesforce-partner-logo" src={salesforcePartnerLogo} />
{/*        					<div>
        						<div className="social-media-icon"><a href="https://www.linkedin.com/company/azur-underwriting-ltd"><FontAwesomeIcon icon={faLinkedin} size="3x"/></a></div>
        						<div className="social-media-icon"><a href="https://twitter.com/Azuruw"><FontAwesomeIcon icon={faTwitter} size="3x"/></a></div>
        					</div>*/}
        				</div>
        				<div className="right-panel">
    				        <Link to="/" onClick={this.scrollToTop}>
    				    		<div className="footer-link">Home</div>
    				    		<br />
    				    	</Link>
                            <div className="footer-link" onClick={() => this.toggleDropdown('what')}>What we do&nbsp;
                                { this.state.whatDropdownOpen && <FontAwesomeIcon icon={faChevronUp} size="1x"/>}
                                { !this.state.whatDropdownOpen && <FontAwesomeIcon icon={faChevronDown} size="1x"/>}
                            </div>
                            <br />
                            { this.state.whatDropdownOpen && <div className="footer-dropdown">
                                <Link to="/whatwedo" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link extra-margin-bottom">Overview</div>
                                    <br />
                                </Link>
                                <hr />
                                <Link to="/productcomponents" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link">Product Components</div>
                                    <br />
                                </Link>
                                <Link to="/workingwithourteam" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link">Working With Our Team</div>
                                    <br />
                                </Link>
                            </div>}
    				    	<div className="footer-link" onClick={() => this.toggleDropdown('who')}>Who we do it for&nbsp;
                                { this.state.whoDropdownOpen && <FontAwesomeIcon icon={faChevronUp} size="1x"/>}
                                { !this.state.whoDropdownOpen && <FontAwesomeIcon icon={faChevronDown} size="1x"/>}
                            </div>
    				    	<br />
                            { this.state.whoDropdownOpen && <div className="footer-dropdown">
                                <Link to="/mga" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link extra-margin-bottom">MGA</div>
                                    <br />
                                </Link>
                                <Link to="/carriers" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link extra-margin-bottom">Carriers</div>
                                    <br />
                                </Link>
                                <Link to="/brokers" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link extra-margin-bottom">Brokers</div>
                                    <br />
                                </Link>
                                <hr />
                                <Link to="/smarthomecasestudy" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link">Smarthome Case Study</div>
                                    <br />
                                </Link>
                                <a href="https://www2.azurtechnology.com/l/1040143/2023-10-23/3m1rjg/1040143/1698051577ZZ8Aho2y/Starfish_Case_Study.pdf" target="_blank"><div className="footer-link dropdown-link">Starfish Case Study</div></a>
                                <br />
                                <a href="https://www2.azurtechnology.com/l/1040143/2023-12-19/3m588m/1040143/1702992405Um9GPu2K/Dual_Case_Study.pdf" target="_blank"><div className="footer-link dropdown-link">Dual Case Study</div></a>
                                <br />
{/*                                <Link to="/casestudies/movinx" onClick={this.scrollToTop}>
                                    <div className="footer-link dropdown-link">Movinx</div>
                                    <br />
                                </Link>*/}
                            </div>}
                            <Link to="/blog" onClick={this.scrollToTop}>
                                <div className="footer-link">Blog</div>
                                <br />
                            </Link>
    				    	<Link to="/aboutus" onClick={this.scrollToTop}>
    				    		<div className="footer-link">About Us</div>
    				    		<br />
    				    	</Link>
                            <Link to="/careers" onClick={this.scrollToTop}>
                                <div className="footer-link">Contact Us</div>
                                <br />
                            </Link>
    				    	<Link to="/contactus" onClick={this.scrollToTop}>
    				    		<div className="footer-link">Careers</div>
    				    	</Link>
        				</div>
        			</div>
        			<div className="two-columns small-print">
        				<div className="left-panel"></div>
        				<div className="right-panel">
                            <a href={privacyPolicyLink} target="_blank" className="privacy-policy-link">Privacy Policy</a>
                            <br /><br />
                            <p>Azur Technology is registered at First Floor,<br/>Templeback, 10 Temple Back, Bristol, BS1 6FL.</p>
                        </div>
        			</div>
        		</div>
        	</footer>
    	);
    }
}

export default Footer;