import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import withLazyLoad from './LazyLoad';
import Loading from './Loading';

class MeetTheTeam extends React.Component {
    state = {
        currentlySelected: null,
        backgroundImageUrl: null
    }

    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        const [teamMembers, backgroundImage] = await Promise.all([
            axios.get(`${process.env.REACT_APP_CMS_URL}/api/team-members?populate=photo&sort=priority%3Aasc`),
            axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-office`)
        ]);

        const currentURLPathName = window.location.pathname.split('/')[2];
        let currentlySelectedTeamMember = null;

        if (currentURLPathName != null) {
            currentlySelectedTeamMember = teamMembers.data.data.filter(teamMember => {
                return teamMember.attributes.name.toLowerCase().split(' ').join('-') === currentURLPathName;
            })[0];

            if (!currentlySelectedTeamMember) {
                return window.location.href = '/notfound';
            }

            document.title = `${currentlySelectedTeamMember.attributes.name} - Azur Technology`;
        } else {
            document.title = 'About us - Azur Technology';
        }

        const backgroundImageUrl = backgroundImage.data.length
            ? backgroundImage.data[0].formats.large.url
            : null;

        this.setState({
            ...teamMembers.data,
            currentlySelected: currentlySelectedTeamMember,
            backgroundImageUrl
        }, () => {
            return this.props.fadeElementsIn(this.props.isVisible);
        });
    }

    openBio = this.openBio.bind(this);

    openBio(e) {
        const currentlySelectedTeamMember = this.state.data.filter(teamMember => teamMember.attributes.name === e.target.dataset.teammembername)[0];
        const urlTeamMemberName = currentlySelectedTeamMember.attributes.name.toLowerCase().split(' ').join('-');

        this.setState({ ...this.state, currentlySelected: currentlySelectedTeamMember }, () => {
            this.props.fadeElementsIn(this.props.isVisible);
            document.title = `${currentlySelectedTeamMember.attributes.name} - Azur Technology`;
            return this.props.navigation(`/aboutus/${urlTeamMemberName}`);
        });
    }

    closeBio = this.closeBio.bind(this);

    closeBio() {
        this.props.navigation('/aboutus');
        this.setState({ ...this.state, currentlySelected: false });
        document.title = 'About us - Azur Technology';
    }

  	render() {
    	return (
    		<div className="meet-the-team-wrapper">
                <div className="meet-the-team-inner">
                    <div className="page-subheading">About us</div>
        			<h1 className="page-header lazy-load"><strong>Azur Technology</strong> is at the forefront of <strong>digitising the insurance sector</strong>, working with some of the biggest names in the business to bring <strong>innovative</strong> and <strong>exciting</strong> products to market. We’re not your typical consultants, we work <strong>closely with our clients</strong> as an <strong>embedded product team</strong>, developing <strong>long-lasting</strong> and <strong>trusted</strong> partnerships.</h1>
                    { !this.state.data && <Loading spinnerColor="scooter" />}
                    <div className="team-member-wrapper">
                        { this.state.data && this.state.data.map((teamMember, i) => <div key={i} className="team-member-card lazy-load">
                                <div className="photo-card" style={{ backgroundImage: `url(${teamMember.attributes.photo.data.attributes.formats.large.url})` }}></div>
                                <div className="name-card">
                                    <h1>{teamMember.attributes.name}</h1>
                                    <h2>{teamMember.attributes.jobtitle}</h2>
                                    <h3>{teamMember.attributes.email}</h3>
                                    <a onClick={this.openBio} data-teammembername={teamMember.attributes.name}>Read bio <FontAwesomeIcon icon={faArrowRight} size="1x" /></a>
                                </div>
                            </div>)
                        }
                    </div>
                    { this.state.currentlySelected && <div className="modal-wrapper">
                        <div className="decorative-elements-wrapper">
                            <div className="background-gradient"></div>
                            <div className="background-gradient-2"></div>
                        </div>
                        <div className="modal-icon-wrapper" onClick={this.closeBio}>
                            <FontAwesomeIcon icon={faXmark} size="2x"/>
                        </div>
                        <div className="modal-central-content">
                            <div className="modal-photo-wrapper lazy-load">
                                <div className="modal-photo-card" style={{ backgroundImage: `url(${this.state.currentlySelected.attributes.photo.data.attributes.formats.medium.url})` }}></div>
                            </div>
                            <div className="modal-text-card lazy-load">
                                <h1 className="text-card-heading">{this.state.currentlySelected.attributes.name}</h1>
                                <div className="text-card-jobtitle">{this.state.currentlySelected.attributes.jobtitle}</div>
                                <ReactMarkdown>{this.state.currentlySelected.attributes.biography}</ReactMarkdown>
                            </div>
                        </div>
                    </div>}
                </div>
                { this.state.backgroundImageUrl && <div className="bottom-contact-banner bottom-photo-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                    <h3>Would you like to join us?</h3>
                    <a href="/careers"><button>Find opportunities <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
                </div>}
    		</div>
    	);
  	}
}

export default withLazyLoad(function(props) {
    const navigation = useNavigate();
    return <MeetTheTeam {...props} navigation={navigation} />;
});