import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import './scss/main.scss';

import Home from './components/Home';
import Navbar from './components/Navbar';
import WhatDoWeDo from './components/WhatDoWeDo';
import WorkingWithOurTeam from './components/WorkingWithOurTeam';
import ProductComponents from './components/ProductComponents';
import WhoMGA from './components/Who_MGA';
import WhoCarriers from './components/Who_Carriers';
import WhoBrokers from './components/Who_Brokers';
import MeetTheTeam from './components/MeetTheTeam';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Careers from './components/Careers';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import NotFound from './components/NotFound';
import Job from './components/Job';
import CaseStudy from './components/CaseStudy';
import Maintenance from './components/Maintenance';

function App() {
  const isMaintenance = process.env.REACT_APP_DISPLAY_MAINTENANCE_PAGE === 'true';

  return isMaintenance ? <Maintenance /> : (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/aboutus"
          element={<MeetTheTeam />}
        />
        <Route path="/aboutus/*"
          element={<MeetTheTeam />}
        />
        <Route path="/whatwedo"
          element={<WhatDoWeDo />}
        />
        <Route path="/workingwithourteam"
          element={<WorkingWithOurTeam />}
        />
        <Route path="/productcomponents"
          element={<ProductComponents />}
        />
        <Route path="/mga"
          element={<WhoMGA />}
        />
        <Route path="/carriers"
          element={<WhoCarriers />}
        />
        <Route path="/brokers"
          element={<WhoBrokers />}
        />
        <Route path="/contactus"
          element={<ContactUs />}
        />
        <Route path="/careers"
          element={<Careers />}
        />
      {/* Temporarily commenting out the Blog route so that */}
        <Route path='/blog/:page'
          exact
          render={(props) => <Blog {...props} />}
        />
        <Route path='/blog'
          exact
          element={<Blog />}
        />
        <Route path='/blog/post'
          render={(props) => <BlogPost {...props} />}
          element={<BlogPost />}
        />
        <Route path='/jobs/post'
          render={(props) => <Job {...props} />}
          element={<Job />}
        />
        <Route path="/smarthomecasestudy"
          render={() => <CaseStudy caseStudyName="smarthome" />}
          element={<CaseStudy caseStudyName="smarthome" />}
        />
{/*        <Route path="/casestudies/movinx"
          render={() => <CaseStudy caseStudyName="movinx" />}
          element={<CaseStudy caseStudyName="movinx" />}
        />*/}
        <Route path="/"
          element={<Home />}
        />
        <Route path="*"
          element={<NotFound />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;