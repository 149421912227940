import React from 'react';

class ScrollPrompt extends React.Component {
	state = {
		scrollTimeout: null
	};

	componentDidMount() {
		window.setTimeout(this.handleScroll, 1500);
		window.addEventListener('scroll', () => this.debounce(this.handleScroll, 1500));
	}

	debounce(method, delay) {
		const scrollPromptWrapper = document.getElementsByClassName('scroll-prompt-wrapper')[0];

		if (scrollPromptWrapper) {
			scrollPromptWrapper.hidden = true;

		    clearTimeout(method._tId);

		    method._tId  = setTimeout(function(){
		        method();
		    }, delay);
		}
	}

	handleScroll() {
		const scrollPromptWrapper = document.getElementsByClassName('scroll-prompt-wrapper')[0];

		if (window.scrollY === 0 && scrollPromptWrapper) {
			document.getElementsByClassName('scroll-prompt-wrapper')[0].hidden = false;
		}
	}

	render() {
	    return (
	    	<div className="scroll-prompt-wrapper" hidden>
	    		<div className="scroll-text">Scroll</div>
	    		<div className="scroll-ticker">
	    			<div className="scroll-ticker-inner"></div>
	    		</div>
	   		</div>
	    );
	}
}

export default ScrollPrompt;