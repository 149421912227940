import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faQuoteLeft, faQuoteRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import withLazyLoad from './LazyLoad';

import engineerImage from '../assets/image/decorative-images/engineer.png';
import productManagerImage from '../assets/image/decorative-images/productmanager.png';

class WorkingWithOurTeam extends React.Component {
	state = {
        backgroundImageUrl: null
    };

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-team`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })

        document.title = 'MGA - Azur Technology';
    }

	 render() {
	    return (
	    	<div className="team-wrapper">
	    		<div className="team-inner">
	    			<div className="page-subheading">Working With Our Team</div>
	    			<h3 className="lazy-load">Our team of developers and product managers have <strong>deep experience</strong> building insurance products. We work closely with you and your team to create the <strong>best product and processes</strong>. We are not your typical consultants and we care about our partnership and all the products we build. </h3>
		    		<div className="desktop-info-panels">
				    	<div className="info-panel lazy-load">
			                <div className="half-width-panel">
			                	<div className="image-wrapper" style={{ backgroundImage: `url(${engineerImage})` }}></div>
			                </div>
			                <div className="half-width-panel text-panel">
			                    <div>
			                    	<h4><strong>Engineers with Salesforce skills</strong></h4>
									<h5>Our engineering team has a unique combination of Salesforce skills and an understanding of the mechanics of an insurance product.</h5>
									<h5>From data models and rating engines, to to third-party integrations and security, we have you covered.</h5>
									<h5>The focus on low-code, reusable architecture and understand how to get the best out of Salesforce’s platform.</h5>
			                    </div>
			                </div>
			            </div>
			            <div className="info-panel lazy-load">
			                <div className="half-width-panel text-panel">
			                    <div>
			                    	<h4><strong>Product Managers with Insurance knowledge</strong></h4>
									<h5>Our Product Managers know exactly how to plan and scope your project, with combined experience across multiple lines of business and across multiple locations.</h5>
									<h5>They will work with your team to understand exactly what you need and they will involve you at every step of the process.</h5>
									<h5>We always tailor our team structure to your needs and we never stack the team with people you don’t need.</h5>
			                    </div>
			                </div>
			                <div className="half-width-panel">
			                    <div className="image-wrapper" style={{ backgroundImage: `url(${productManagerImage})` }}></div>
			                </div>
			            </div>
		            </div>
		            <div className="mobile-info-panels">
		            	<div className="full-width-panel fixed-height">
	                    	<div className="image-wrapper" style={{ backgroundImage: `url(${engineerImage})` }}></div>
	                	</div>
	                	<div className="full-width-panel">
	                    	<div className="text-panel-mobile">
	                        	<h3 className="h3-mobile">Engineers with Salesforce skills</h3>
	                        	<p className="text-paragraph-mobile">Our engineering team has a unique combination of salesforce skills and an understanding of the mechanics of an insurance product.</p>
	                        	<p className="text-paragraph-mobile">From data models and rating engines, to to third-party integrations and security, we have you covered.</p>
	                        	<p className="text-paragraph-mobile">The focus on low-code, reusable architecture and understand how to get the best out of Salesforce’s platform.</p>
	                    	</div>
	                	</div>
	                	<div className="full-width-panel fixed-height">
	                    	<div className="image-wrapper" style={{ backgroundImage: `url(${productManagerImage})` }}></div>
	                	</div>
	                	<div className="full-width-panel">
	                    	<div className="text-panel-mobile">
	                        	<h3 className="h3-mobile">Product Managers with Insurance knowledge</h3>
	                        	<p className="text-paragraph-mobile">Our Product Managers know exactly how to plan and scope your project, with combined experience across multiple lines of business and across multiple locations.</p>
	                        	<p className="text-paragraph-mobile">They will work with your team to understand exactly what you need and they will involve you at every step of the process.</p>
	                        	<p className="text-paragraph-mobile">We always tailor our team structure to your needs and we never stack the team with people you don’t need.</p>
	                    	</div>
	                	</div>
		            </div>
{/*			        <div className="quote-wrapper lazy-load">
			        	<div className="mobile-quote-mark mobile-open-quote"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></div>
		                <h4><span className="quotation-mark"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></span>Insert a quote here<span className="quotation-mark"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></span></h4>
		                <div className="mobile-quote-mark mobile-closed-quote"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></div>
		                <h5>- Azur PCG</h5>
		            </div>*/}
            	</div>
        		{this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                	<h3>Ready to be our next success story?</h3>
                	<a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
            	</div>}
	    	</div>
	    );
	}
}

export default withLazyLoad(WorkingWithOurTeam);