import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faUsers, faServer, faToolbox, faQuoteLeft, faFileLines, faScaleBalanced, faCircleCheck, faArrowRight, faFilePdf } from '@fortawesome/free-solid-svg-icons';

// import smarthomeQuote1 from '../assets/image/screenshots/smarthome-quote-1.png';
import smarthomeQuote2 from '../assets/image/screenshots/smarthome-quote-2.png';
import smarthomeQuote3 from '../assets/image/screenshots/smarthome-quote-3.png';
import smarthomeQuote4 from '../assets/image/screenshots/smarthome-quote-4.png';

// import smartdocs1 from '../assets/image/screenshots/smartdocs-1.png';
import smartdocs2 from '../assets/image/screenshots/smartdocs-2.png';
import smartdocs3 from '../assets/image/screenshots/smartdocs-3.png';
import smartdocs4 from '../assets/image/screenshots/smartdocs-4.png';

import smarthomeQuotedetail1 from '../assets/image/screenshots/quotedetail-1.png';
import smarthomeQuotedetail2 from '../assets/image/screenshots/quotedetail-2.png';
import smarthomeQuotedetail3 from '../assets/image/screenshots/quotedetail-3.png';
// import smarthomeQuotedetail4 from '../assets/image/screenshots/quotedetail-4.png';

import smarthomeDashboard from '../assets/image/screenshots/smarthome-dashboard.png';
import ScrollPrompt from './ScrollPrompt';
import withLazyLoad from './LazyLoad';

import celentLogo from '../assets/image/celent-logo.png';
import datosLogo from '../assets/image/datos-logo.png';
import salesforcePartnerLogo from '../assets/image/salesforce-partner-logo.png';
import stableLogo from '../assets/image/customer-logos/stable-logo.png';
import aigLogo from '../assets/image/customer-logos/aig-logo.png';
import elmsureLogo from '../assets/image/customer-logos/elmsure-logo-white.png';
import movinxLogo from '../assets/image/customer-logos/movinx-logo-white.png';
import starfishLogo from '../assets/image/customer-logos/starfish-logo-white.png';
import salesforceLogo from '../assets/image/customer-logos/salesforce-logo-white.png';
import pwcLogo from '../assets/image/customer-logos/pwc-logo-white.png';
import zurichLogo from '../assets/image/customer-logos/zurich-logo-white.png';

class WhatDoWeDo extends React.Component {
    state = {
        backgroundImageUrl: null
    };

    async componentDidMount() {

        const [caseStudyThumbnail, backgroundImage] = await Promise.all([
            axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=case-study-thumbnail`),
            axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-meeting`)
        ]);

        const caseStudyThumbnailUrl = caseStudyThumbnail.data.length
            ? caseStudyThumbnail.data[0].formats.medium.url
            : null;

        const backgroundImageUrl = backgroundImage.data.length
            ? backgroundImage.data[0].formats.large.url
            : null;

        this.setState({
            caseStudyThumbnailUrl, backgroundImageUrl
        });

        document.title = 'What we do - Azur Technology';
    }

  render() {
    return (
		<div className="what-wrapper">
            <div className="what-inner">
                <div className="decorative-circle-wrapper">
                    <div className="header-background-circle lazy-load lazy-load-vertically-center"></div>
                    <div className="header-background-circle-2 lazy-load lazy-load-vertically-center"></div>
                    <div className="header-background-stripe"></div>
                </div>
                <div className="what-hero">
                    <div className="page-subheading">Overview</div>
                    <div className="columns">
                        <div className="two-thirds-width-column">
                            <h1 className="lazy-load"><strong>Award-winning</strong></h1>
                            <h2 className="main-title lazy-load">Launch, accelerate, and operate your next insurance product with a best-in-class platform and our team of product experts and engineers.</h2>
                        </div>
                        <div className="one-third-width-column inline-logo-column">
                            <div className="inline-logo-wrapper">
                                <img className="inline-logo lazy-load" src={celentLogo} />
                                <img className="inline-logo lazy-load" src={datosLogo} />
                            </div>
                        </div>
                    </div>
                    <div className="hero-bottom">
                        <ScrollPrompt />
                    </div>
                </div>
                <div className="columns">
                    <h3 className="h3-lesser-padding lazy-load two-thirds-width-column">Built by MGAs for MGAs, our solutions feature all the components you would expect to manage the end-to-end insurance policy life cycle, <strong>PLUS</strong> some truly innovative features to transform your business and drive margin. All built on a platform you can trust – Salesforce Financial Services Cloud.</h3>
                </div>
                <div className="decorative-circle-wrapper">
                    <div className="header-background-circle-3 lazy-load lazy-load-vertically-center"></div>
                    <div className="header-background-circle-4 lazy-load lazy-load-vertically-center"></div>
                </div>
                <div className="columns lazy-load">
                    <div className="full-width-column"><h3 className="h3-lesser-padding extra-padding-above"><strong>Platform Features</strong></h3></div>
                </div>
                <div className="columns">
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M16,23c0,.552-.447,1-1,1H6c-2.757,0-5-2.243-5-5V5C1,2.243,3.243,0,6,0h4.515c1.869,0,3.627,.728,4.95,2.05l3.484,3.486c.271,.271,.523,.568,.748,.883,.321,.449,.217,1.074-.232,1.395-.449,.32-1.075,.217-1.395-.233-.161-.225-.341-.438-.534-.63l-3.485-3.486c-.318-.318-.671-.587-1.051-.805V7c0,.551,.448,1,1,1h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023H6c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3H15c.553,0,1,.448,1,1Zm5.685-6.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551,.448-1,1-1h2.268c.356,0,.688,.192,.867,.5,.275,.478,.885,.641,1.366,.365,.478-.277,.642-.888,.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-1c0-.552-.447-1-1-1s-1,.448-1,1v1c-1.654,0-3,1.346-3,3,0,1.36,.974,2.51,2.315,2.733l3.041,.507c.373,.062,.644,.382,.644,.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.275-.479-.886-.642-1.366-.365-.478,.277-.642,.888-.364,1.366,.534,.925,1.53,1.499,2.598,1.499h.268v1c0,.552,.447,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733Zm-14.185-1.267h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-1.378,0-2.5,1.122-2.5,2.5v2c0,1.378,1.122,2.5,2.5,2.5h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-.276,0-.5-.224-.5-.5v-2c0-.276,.224-.5,.5-.5Zm-1.5-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Zm0-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Submissions & Quotes</strong></h4>
                                <p>Streamline submissions and generate accurate quotes in real-time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M5,6.5c0-.83,.67-1.5,1.5-1.5s1.5,.67,1.5,1.5-.67,1.5-1.5,1.5-1.5-.67-1.5-1.5Zm19-.5V14c0,2.76-2.24,5-5,5h-6v2h4c.55,0,1,.45,1,1s-.45,1-1,1H7c-.55,0-1-.45-1-1s.45-1,1-1h4v-2H5c-2.76,0-5-2.24-5-5V6C0,3.24,2.24,1,5,1h14c2.76,0,5,2.24,5,5ZM2,14c0,.72,.26,1.38,.68,1.9l5.93-5.93c1.32-1.32,3.46-1.32,4.78,0l.63,.64c.54,.54,1.41,.54,1.95,0l5.78-5.78c-.46-1.07-1.52-1.83-2.76-1.83H5c-1.65,0-3,1.35-3,3V14Zm20,0V7.41l-4.61,4.61c-1.32,1.32-3.46,1.32-4.78,0l-.63-.64c-.54-.54-1.41-.54-1.95,0l-5.56,5.56c.17,.03,.35,.05,.54,.05h14c1.65,0,3-1.35,3-3Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Broker Portal</strong></h4>
                                <p>Make life easy for your brokers and become their preferred partner.</p>
                                <p><strong><em>Increase submissions by 75%</em></strong></p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23.12,11.88c-1.13-1.13-3.11-1.13-4.24,0l-7,7c-.57,.57-.88,1.32-.88,2.12v2c0,.55,.45,1,1,1h2c.8,0,1.55-.31,2.12-.88l7-7c.57-.57,.88-1.32,.88-2.12s-.31-1.55-.88-2.12Zm-1.41,2.83l-7,7c-.19,.19-.44,.29-.71,.29h-1v-1c0-.26,.11-.52,.29-.71l7-7c.38-.38,1.04-.38,1.41,0,.19,.19,.29,.44,.29,.71s-.1,.52-.29,.71Zm-7.71-1.21c0-.83-.67-1.5-1.5-1.5s-1.5,.67-1.5,1.5c0,.32,.1,.67,.29,.99-.32,.28-.74,.51-1.29,.51-.44,0-.88-.13-1.3-.34,.81-1.16,1.3-2.55,1.3-3.66,0-1.65-1.35-3-3-3s-3,1.35-3,3c0,1.22,.68,2.65,1.71,3.8-.24,.13-.48,.2-.71,.2-.55,0-1,.45-1,1s.45,1,1,1c.83,0,1.61-.33,2.3-.85,.84,.53,1.76,.85,2.7,.85,2.6,0,4-2.48,4-3.5Zm-6.87-.1c-.68-.79-1.13-1.72-1.13-2.4,0-.55,.45-1,1-1s1,.45,1,1c0,.72-.35,1.63-.87,2.4Zm1.87,7.6c0,.55-.45,1-1,1h-3c-2.76,0-5-2.24-5-5V5C0,2.24,2.24,0,5,0h5.76c1.07,0,2.07,.42,2.83,1.17l3.24,3.24c.76,.76,1.17,1.76,1.17,2.83v1.76c0,.55-.45,1-1,1s-1-.45-1-1v-1.76c0-.08,0-.16-.02-.24h-2.98c-1.1,0-2-.9-2-2V2.02c-.08,0-.16-.02-.24-.02H5c-1.65,0-3,1.35-3,3v12c0,1.65,1.35,3,3,3h3c.55,0,1,.45,1,1Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Policy Admin</strong></h4>
                                <p>Manage policies and policy admin with ease, including renewals and cancellation processing, endorsements and more.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24.013,15.331,23.49,11.1a4.471,4.471,0,0,0-2.834-4.081L16.7,5.378a4.933,4.933,0,0,0-5.073.875L7.952,8.979a9.066,9.066,0,0,0-2.295,3.283s-.428.77-.445.811a3.441,3.441,0,0,0,.087,2.58,3.251,3.251,0,0,0,.726,1.06l-.033.082a2.5,2.5,0,0,0,4.6,1.964l4.8,1.925a2.5,2.5,0,0,0,4.686,1.738l.014-.035A3.007,3.007,0,0,0,23,20.5l.412-1.04A9.04,9.04,0,0,0,24.013,15.331ZM12.89,7.8a2.977,2.977,0,0,1,3.066-.568l3.955,1.64A2.541,2.541,0,0,1,21.5,11.289c0,.012.419,3.388.419,3.388L10.028,9.924ZM8.131,18.183l-.01,0,.02.008Zm9.429,3.781-.009,0,.017.006Zm3.582-2.2a1,1,0,0,1-1.288.564L7.635,15.419a1.009,1.009,0,0,1-.5-.561,1.484,1.484,0,0,1-.081-1s.421-.757.437-.8a7.236,7.236,0,0,1,.916-1.635l1.62.648-.325.8a1,1,0,0,0,1.854.752l.328-.81,6.648,2.657-.33.813a1,1,0,1,0,1.854.752l.333-.822,1.622.648a6.987,6.987,0,0,1-.455,1.851ZM4,11a1,1,0,0,1-1,1H1a1,1,0,0,1-.768-1.64L1.823,8.451a.7.7,0,0,0,0-.9L.232,5.64A1,1,0,0,1,1,4H3.3A.705.705,0,0,0,4,3.3V1A1,1,0,0,1,5.64.232L7.549,1.823a.7.7,0,0,0,.9,0L10.36.232A1,1,0,0,1,12,1V3a1,1,0,0,1-1.992.128l-.277.231a2.7,2.7,0,0,1-3.462,0L6,3.135V3.3A2.707,2.707,0,0,1,3.3,6H3.135l.224.269a2.7,2.7,0,0,1,0,3.462l-.231.277A1,1,0,0,1,4,11Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Claims</strong></h4>
                                <p>Manage and track claims effectively, including reserving and adjusting, to improve customer service and reduce costs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M23.02,8.79c-.59-.54-1.36-.81-2.17-.78-.8,.04-1.54,.39-2.09,.98l-3.22,3.53c-.55-.91-1.55-1.52-2.69-1.52H4c-2.21,0-4,1.79-4,4v5c0,2.21,1.79,4,4,4h4.96c2.85,0,5.57-1.22,7.47-3.35l6.81-7.64c1.09-1.23,.99-3.12-.22-4.23Zm-1.27,2.9l-6.81,7.64c-1.52,1.7-3.69,2.68-5.97,2.68H4c-1.1,0-2-.9-2-2v-5c0-1.1,.9-2,2-2H12.86c.63,0,1.14,.51,1.14,1.14,0,.56-.42,1.05-.98,1.13l-5.16,.74c-.55,.08-.93,.58-.85,1.13,.08,.55,.59,.93,1.13,.85l5.16-.74c1.18-.17,2.13-.99,2.51-2.06l4.43-4.86c.18-.2,.43-.32,.7-.33,.27,0,.53,.08,.73,.26,.41,.37,.44,1.01,.07,1.42Z"/><path d="M9,10h.38c1.45,0,2.62-1.18,2.62-2.62,0-1.29-.92-2.38-2.19-2.59l-3.28-.55c-.3-.05-.52-.31-.52-.62,0-.34,.28-.62,.62-.62h2.64c.36,0,.69,.19,.87,.5,.27,.48,.88,.64,1.37,.36,.48-.28,.64-.89,.36-1.37-.53-.92-1.53-1.5-2.6-1.5h-.27c0-.55-.45-1-1-1s-1,.45-1,1h-.38c-1.45,0-2.62,1.18-2.62,2.62,0,1.29,.92,2.38,2.19,2.59l3.28,.55c.3,.05,.52,.31,.52,.62,0,.34-.28,.62-.62,.62h-2.64c-.36,0-.69-.19-.87-.5-.28-.48-.89-.64-1.37-.36-.48,.28-.64,.89-.36,1.37,.53,.92,1.53,1.5,2.6,1.5h.27c0,.55,.45,1,1,1s1-.45,1-1Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Billing & Accounting</strong></h4>
                                <p>Streamline billing and accounting processes, including premium collection and commission management.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,23c0,.552-.448,1-1,1H3c-1.654,0-3-1.346-3-3V1C.006-.308,1.994-.307,2,1V21c0,.551,.449,1,1,1H23c.552,0,1,.448,1,1Zm-3-18h-4c-1.308,.006-1.307,1.994,0,2h3.563l-4.857,4.707c-.377,.378-1.036,.378-1.413,0-.041-.04-1.239-.893-1.239-.893-1.138-1.073-3.077-1.033-4.162,.051l-4.586,4.414c-.398,.383-.41,1.016-.027,1.414,.384,.398,1.016,.41,1.414,.027l4.6-4.428c.377-.378,1.036-.378,1.413,0,.041,.04,1.239,.893,1.239,.893,1.139,1.074,3.076,1.036,4.164-.054l4.89-4.74v3.607c.006,1.308,1.994,1.307,2,0v-4c0-1.654-1.346-3-3-3Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Customized Reporting</strong></h4>
                                <p>Gain valuable insights across your entire organisation, and stay compliant, with fully- customizable, enterprise-level reporting and dashboards.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"/><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Automated underwriting</strong></h4>
                                <p>Use triage rules to auto accept or decline submissions.</p>
                                <p><strong><em>Increase UW efficiency by 68%</em></strong></p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M24,11c0-1.568-.752-3.04-2-3.979v-.021c0-1.897-1.327-3.489-3.102-3.898-.409-1.774-2.002-3.102-3.898-3.102-1.194,0-2.267,.526-3,1.357-.733-.832-1.806-1.357-3-1.357-1.896,0-3.489,1.327-3.898,3.102-1.774,.409-3.102,2.001-3.102,3.898v.021c-1.248,.939-2,2.41-2,3.979,0,.886,.235,1.737,.686,2.5-.45,.763-.686,1.614-.686,2.5,0,1.686,.858,3.244,2.267,4.166,.719,2.278,2.812,3.834,5.233,3.834,1.858,0,3.504-.926,4.5-2.342,.996,1.415,2.642,2.342,4.5,2.342,2.422,0,4.515-1.556,5.233-3.834,1.408-.922,2.267-2.48,2.267-4.166,0-.886-.235-1.737-.686-2.5,.45-.763,.686-1.614,.686-2.5ZM7.5,22c-1.634,0-3.033-1.115-3.401-2.712-.065-.281-.248-.52-.502-.656-.985-.528-1.597-1.536-1.597-2.631,0-.675,.234-1.322,.679-1.872,.296-.367,.296-.89,0-1.257-.444-.549-.679-1.196-.679-1.872,0-1.07,.591-2.067,1.543-2.603,.37-.208,.568-.627,.494-1.045-.02-.115-.037-.231-.037-.352,0-1.103,.897-2,2-2,.553,0,1-.448,1-1,0-1.103,.897-2,2-2s2,.897,2,2v4h-2.268c-.346-.598-.992-1-1.732-1-1.105,0-2,.895-2,2s.895,2,2,2c.74,0,1.386-.402,1.732-1h2.268v5h-2.268c-.346-.598-.992-1-1.732-1-1.105,0-2,.895-2,2s.895,2,2,2c.74,0,1.386-.402,1.732-1h2.268v1.5c0,1.93-1.57,3.5-3.5,3.5Zm13.821-7.872c.444,.549,.679,1.196,.679,1.872,0,1.096-.611,2.104-1.597,2.631-.254,.136-.437,.375-.502,.656-.368,1.597-1.768,2.712-3.401,2.712-1.93,0-3.5-1.57-3.5-3.5v-4.5h2c1.654,0,3-1.346,3-3v-.268c.598-.346,1-.992,1-1.732,0-1.105-.895-2-2-2s-2,.895-2,2c0,.74,.402,1.386,1,1.732v.268c0,.551-.448,1-1,1h-2V4c0-1.103,.897-2,2-2s2,.897,2,2c0,.552,.447,1,1,1,1.103,0,2,.897,2,2,0,.121-.018,.237-.037,.352-.074,.418,.124,.837,.494,1.045,.952,.535,1.543,1.533,1.543,2.603,0,.675-.234,1.322-.679,1.872-.296,.367-.296,.89,0,1.257Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>AI-driven Insights</strong></h4>
                                <p>Identify patterns and risks to provide real-time insights and improve efficiency and decision-making.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M10.357,2.921l-1.416,2.3a1,1,0,0,1-1.7-1.048l1.416-2.3a3.948,3.948,0,0,1,6.692,0l4.2,6.834.441-2.365a1,1,0,0,1,1.967.366l-.5,2.67a3,3,0,0,1-3.529,2.393l-2.63-.518a1,1,0,0,1,.386-1.962l2.133.421L13.643,2.921A1.934,1.934,0,0,0,10.357,2.921ZM23.409,14.98l-.968-1.572a1,1,0,1,0-1.7,1.048l.968,1.572a1.934,1.934,0,0,1,.041,1.98A1.91,1.91,0,0,1,20.062,19H12.676l1.271-1.269a1,1,0,0,0-1.412-1.416L10.843,18A2.862,2.862,0,0,0,10,19.945v.062a2.885,2.885,0,0,0,.858,2.078l1.682,1.652a1,1,0,1,0,1.4-1.427L12.609,21h7.453a3.948,3.948,0,0,0,3.347-6.02ZM8.047,9.146a3,3,0,0,0-3.53-2.393l-2.629.518a1,1,0,1,0,.386,1.962l2.108-.415L.591,14.98A3.948,3.948,0,0,0,3.937,21H7.08a1,1,0,0,0,0-2H3.937a1.91,1.91,0,0,1-1.684-.992,1.934,1.934,0,0,1,.041-1.98L6.132,9.79l.446,2.394a1,1,0,0,0,1.967-.368Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Renewals</strong></h4>
                                <p>Maximize customer retention with effective renewal management and automated communication.</p>
                            </div>
                        </div>
                    </div>
                    <div className="half-width-column lazy-load">
                        <div className="icon-bullet-wrapper">
                            <div className="icon-wrapper">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="512" height="512"><path d="M17.974,7.146c-.331-.066-.602-.273-.742-.569-1.55-3.271-5.143-5.1-8.734-4.438-3.272,.6-5.837,3.212-6.384,6.501-.162,.971-.15,1.943,.033,2.89,.06,.309-.073,.653-.346,.901-1.145,1.041-1.801,2.524-1.801,4.07,0,3.032,2.467,5.5,5.5,5.5h11c4.136,0,7.5-3.364,7.5-7.5,0-3.565-2.534-6.658-6.026-7.354Zm-1.474,12.854H5.5c-1.93,0-3.5-1.57-3.5-3.5,0-.983,.418-1.928,1.146-2.59,.786-.715,1.155-1.773,.963-2.763-.138-.712-.146-1.445-.024-2.181,.403-2.422,2.365-4.421,4.771-4.862,.385-.07,.768-.104,1.146-.104,2.312,0,4.405,1.289,5.422,3.434,.413,.872,1.2,1.482,2.158,1.673,2.56,.511,4.417,2.779,4.417,5.394,0,3.032-2.468,5.5-5.5,5.5Zm-1.379-7.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1.707-1.707v5c0,.553-.448,1-1,1s-1-.447-1-1v-5l-1.707,1.707c-.391,.391-1.023,.391-1.414,0s-.391-1.023,0-1.414l2.707-2.707c.386-.386,.893-.58,1.4-.583l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l2.707,2.707Z"/></svg>
                            </div>
                            <div className="text-wrapper">
                                <h4><strong>Augmented underwriting</strong></h4>
                                <p>Make faster, smarter decisions with API data enrichment and AI-driven insights, all in one place.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondary-hero-hero">
                    <div className="full-width-panel lazy-load">
                        <div className="text-panel">
                            <div className="columns">
                                <div className="one-third-width-column">
                                    <div className="decorative-circle content-circle lazy-load lazy-load-vertically-center">
                                        <div className="content-circle-group">
                                            <p className="center-align">Increase<br/>submissions by</p>
                                            <h3 className="center-align"><strong>75%</strong></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="one-third-width-column">
                                    <div className="decorative-circle content-circle lazy-load lazy-load-vertically-center">
                                        <div className="content-circle-group">
                                            <p className="center-align">Increase<br/>UW efficiency by</p>
                                            <h3 className="center-align"><strong>68%</strong></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="one-third-width-column">
                                    <div className="decorative-circle content-circle lazy-load lazy-load-vertically-center">
                                        <div className="content-circle-group">
                                            <p className="center-align">Double<br/>net margin to</p>
                                            <h3 className="center-align"><strong>40%</strong></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondary-hero">
                    <div className="decorative-circle-wrapper">
                        <div className="header-background-stripe-2"></div>
                        <div className="header-background-circle-5 lazy-load lazy-load-vertically-center"></div>
                    </div>
                    <div className="columns lazy-load">
                        <div className="one-third-width-column">
                            <img className="salesforce-logo" src={salesforcePartnerLogo} />
                        </div>
                        <div className="two-thirds-width-column">
                            <h1><strong><br/>Built on Salesforce</strong></h1>
                            <h3 className="h3-lesser-padding">All of our solutions are built on Salesforce’s Financial Services Cloud, offering unparalleled reliability, security and performance.</h3>
                        </div>
                    </div>
                </div>
                <div className="secondary-hero">
                    <div className="decorative-circle-wrapper">
                        <div className="header-background-circle-6 lazy-load lazy-load-vertically-center"></div>
                    </div>
                    <div className="columns lazy-load">
                        <div className="full-width-column"><h3 className="h3-lesser-padding"><strong>Use technology to become - Faster, smarter & more profitable.</strong></h3></div>
                    </div>
                    <div className="columns lazy-load">
                        <div className="one-third-width-column">
                            <div className="background-box">
                                <h4 className="center-align"><strong>Faster</strong></h4>
                                <p><strong>Consolidated UW workbench</strong></p>
                                <p>All your data and tools in one place.</p><br />
                                <p><strong>Automated Underwriting</strong></p>
                                <p>Use triage rules to auto accept or decline submissions.</p><br />
                                <p><strong>Data Enrichment</strong></p>
                                <p>Streamline broker submission and UW processes with 3rd party data.</p>
                            </div>
                        </div>
                        <div className="one-third-width-column">
                            <div className="background-box">
                                <h4 className="center-align"><strong>Smarter</strong></h4>
                                <p><strong>AI-driven insights</strong></p>
                                <p>Improved efficiency and decision-making.</p><br />
                                <p><strong>Reporting & BI tools</strong></p>
                                <p>Build custom reports & gain valuable insights across your entire organisation.</p><br />
                                <p><strong>Salesforce Cloud</strong></p>
                                <p>Enterprise-grade security and performance on a trusted cloud platform.</p>
                            </div>
                        </div>
                        <div className="one-third-width-column">
                            <div className="background-box">
                                <h4 className="center-align"><strong>More profitable</strong></h4>
                                <p><strong>Increase submissions</strong></p>
                                <p>Simplify the broker journey to drive more submissions.</p><br />
                                <p><strong>Improve underwriter efficiency</strong></p>
                                <p>Automation, data enrichment and a consolidated workbench.</p><br />
                                <p><strong>Win & retain more business</strong></p>
                                <p>A better user experience and quicker decision making.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondary-hero">
                    <div className="hero hero-less-height">
                        <div className="info-panel">
                            <div className="header-background-stripe-3"></div>
                            <div className="half-width-panel lazy-load">
                                <div className="text-panel">
                                    <div>
                                        {this.state.caseStudyThumbnailUrl && <div className="testimony-headshot" style={{ backgroundImage: `url(${this.state.caseStudyThumbnailUrl})` }}></div>}
                                    </div>
                                </div>
                            </div>
                            <div className="half-width-panel lazy-load">
                                <div className="text-panel">
                                    <h4 className="lazy-load froly-text"><strong>Guide</strong></h4>
                                    <h4 className="lazy-load"><strong>Insurance Technology - for show or dough?</strong></h4>
                                    <h4 className="lazy-load">How the latest technology, through the use of data enrichment, automation, and better UX is driving unprecedented margins.</h4>
                                    <div>
                                        <a className="pdf-url-link" href="https://www2.azurtechnology.com/show-or-dough" target="_blank">
                                            <button className="pdf-url-button">
                                                <div><FontAwesomeIcon icon={faFilePdf} size="2x" /></div>
                                                Read Article
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="secondary-hero">
                    <div className="logos-section">
                        <div className="logos-wrapper lazy-load">
                            <div className="lesser-width"><img src={aigLogo} /></div>
                            <div><img src={stableLogo} /></div>
                            <div><img src={elmsureLogo} /></div>
                            <div><img src={movinxLogo} /></div>
                            <div><img src={starfishLogo} /></div>
                            <div className="lesser-width"><img src={salesforceLogo} /></div>
                            <div className="lesser-width"><img src={pwcLogo} /></div>
                            <div><img src={zurichLogo} /></div>
                        </div>
                    </div>
                </div>
                <div className="secondary-hero">
                    <div className="decorative-circle-wrapper">
                        <div className="header-background-circle-7 lazy-load lazy-load-vertically-center"></div>
                    </div>
                    <div className="columns lazy-load">
                        <div className="full-width-column"><h1><strong>Our products</strong><br/><br/></h1></div>
                    </div>
                    <div className="columns lazy-load">
                        <div className="half-width-column">
                            <div className="background-box alternative-color">
                                <h4><strong>MGA Express</strong></h4>
                                <p>Our <strong>next-generation</strong> digital insurance solution.</p><br />
                                <p>Everything you expect to build, manage, and sell insurance products, plus the latest in automation to turbo-charge efficiency and margin.</p><br />
                                <p><strong><em>Build and launch quickly and cost-effectively.</em></strong></p>
                            </div>
                        </div>
                        <div className="half-width-column">
                            <div className="background-box">
                                <h4><strong>MGA Connect</strong></h4>
                                <p>Our <strong>enterprise</strong> digital insurance solution.</p><br />
                                <p>Everything you expect to build, manage, and sell insurance products, plus the latest in automation and AI technology to turbo-charge efficiency and margin.</p><br />
                                <p><strong><em>Fully customizable and endlessly scalable. Build to your specific requirements.</em></strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                <h3>Ready to transform your insurance business?</h3>
                <a href="/contactus"><button>Contact us <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
            </div>}
    	</div>
    );
  }
}

export default withLazyLoad(WhatDoWeDo);