import React from 'react';
import { Link } from 'react-router-dom';
import azurlogo from '../assets/image/azur-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faChevronDown, faChevronUp, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// import starfishPdf from '../assets/pdf/starfish.pdf';

class Navbar extends React.Component {
	state = {
		showMobileMenu: false,
		showWhoDropdown: false,
		showWhoDropdownDesktop: false,
		showWhoDropdownDesktopTransitionInProgress: false,
		showWhatDropdown: false,
		showWhatDropdownDesktop: false,
		showWhatDropdownDesktopTransitionInProgress: false
	}

	componentDidMount() {
		this.findScrollDirection();
	}

	toggleMobileMenu = () => {
		this.setState({
			...this.state,
			showMobileMenu: !this.state.showMobileMenu,
			showWhoDropdown: false,
			showWhatDropdown: false
		});
	}

	toggleMobileDropdown = dropdownType => {
		const stateToSet = {...this.state};

		switch(dropdownType) {
			case 'what':
				stateToSet.showWhatDropdown = !this.state.showWhatDropdown;
				break;
			case 'who':
				stateToSet.showWhoDropdown = !this.state.showWhoDropdown;
				break;
		}

		this.setState(stateToSet);
	}

	toggleDesktopDropdown = dropdownType => {
		const stateToSet = {...this.state};

		switch(dropdownType) {
			case 'what':
				stateToSet.showWhatDropdownDesktop = !this.state.showWhatDropdownDesktop;
				stateToSet.showWhoDropdownDesktop = false;
				break;
			case 'who':
				stateToSet.showWhoDropdownDesktop = !this.state.showWhoDropdownDesktop;
				stateToSet.showWhatDropdownDesktop = false;
				break;
		}

		this.setState(stateToSet);
	}

	closeDesktopDropdown = () => {
		this.setState({
			...this.state,
			showWhoDropdownDesktop: false,
			showWhatDropdownDesktop: false
		});

		this.scrollToTop();
	}

	offMobileMenu = () => {
		this.setState({
			...this.state,
			showMobileMenu: false
		});

		this.scrollToTop();
	}

	scrollToTop = () => {
		window.scrollTo(0, 0);
	}

	findScrollDirection() {
		let scrollPos = 0;

		window.addEventListener('scroll', function(){
		  	if ((document.body.getBoundingClientRect()).top > scrollPos) {
		  		document.getElementsByTagName('header')[0].classList.add('show-header');
		  		document.getElementsByTagName('header')[0].classList.remove('hide-header');
		  	} else {
		  		if ((document.body.getBoundingClientRect().top < -120 && this.screen.width > 1220)
		  			|| (document.body.getBoundingClientRect().top < -80 && this.screen.width <= 1220)) {
		  			document.getElementsByTagName('header')[0].classList.remove('show-header');
		  			document.getElementsByTagName('header')[0].classList.add('hide-header');
		  		}
		  	}
			scrollPos = (document.body.getBoundingClientRect()).top;
		});
	}

  	render() {
	    return (
	    	<div className="header-wrapper">
	    		<div className="mobile-padding"></div>
		    	<header className={this.state.showMobileMenu ? 'show-header mobile-menu-open' : 'show-header'}>
		    		<div className="nav-wrapper">
		    			<a className="azur-logo" href="/">
		    				<img src={azurlogo} />
		    			</a>
		    			<div className="desktop-nav-menu">
			    			<nav>
						        {/*<Link to="/">
						    		<div className="navbar-link" onClick={this.scrollToTop}>Home</div>
						    	</Link>*/}
						    	<div className="hoverable-dropdown">
						    		<div className="dropdown-navbar-link navbar-link"
						    			onMouseEnter={e => this.toggleDesktopDropdown('what')}
						    			onMouseLeave={e => this.toggleDesktopDropdown('what')}
						    		>What we do <span className="dropdown-arrow"><FontAwesomeIcon icon={faChevronDown} size="1x"/></span>
						    			{this.state.showWhatDropdownDesktop && <div className="dropdown-wrapper">
						    				<div className="dropdown-top-spacing">
							    				<div className="dropdown-tail"></div>
							    			</div>
						    				<div className="dropdown">
								    			<Link to="/whatwedo">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>Overview <span className="chevron-right">
								    						<FontAwesomeIcon icon={faChevronRight} size="1x"/>
								    					</span>
								    				</div>
								    			</Link>
								    			<hr/>
								    			<Link to="/productcomponents">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>Product Components <span className="chevron-right">
								    					<FontAwesomeIcon icon={faChevronRight} size="1x"/></span>
								    				</div>
								    			</Link>
								    			<Link to="/workingwithourteam">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>Working With Our Team <span className="chevron-right">
								    					<FontAwesomeIcon icon={faChevronRight} size="1x"/></span>
								    				</div>
								    			</Link>
								    		</div>
						    			</div>}
						    		</div>
						    	</div>
						    	<div className="hoverable-dropdown">
						    		<div className="dropdown-navbar-link navbar-link"
						    			onMouseEnter={e => this.toggleDesktopDropdown('who')}
						    			onMouseLeave={e => this.toggleDesktopDropdown('who')}
						    		>Who we do it for <span className="dropdown-arrow"><FontAwesomeIcon icon={faChevronDown} size="1x"/></span>
						    			{this.state.showWhoDropdownDesktop && <div className="dropdown-wrapper">
						    				<div className="dropdown-top-spacing">
							    				<div className="dropdown-tail"></div>
							    			</div>
						    				<div className="dropdown">
								    			<Link to="/mga">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>MGA <span className="chevron-right">
								    						<FontAwesomeIcon icon={faChevronRight} size="1x"/>
								    					</span>
								    				</div>
								    			</Link>
								    			<Link to="/carriers">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>Carriers <span className="chevron-right">
								    						<FontAwesomeIcon icon={faChevronRight} size="1x"/>
								    					</span>
								    				</div>
								    			</Link>
								    			<Link to="/brokers">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>Brokers <span className="chevron-right">
								    						<FontAwesomeIcon icon={faChevronRight} size="1x"/>
								    					</span>
								    				</div>
								    			</Link>
								    			<hr/>
								    			<Link to="/smarthomecasestudy">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>SmartHome Case Study <span className="chevron-right">
								    					<FontAwesomeIcon icon={faChevronRight} size="1x"/></span>
								    				</div>
								    			</Link>
							    				<a href="https://www2.azurtechnology.com/l/1040143/2023-10-23/3m1rjg/1040143/1698051577ZZ8Aho2y/Starfish_Case_Study.pdf" target="_blank"><div className="navbar-link" onClick={this.closeDesktopDropdown}>Starfish Case Study <span className="chevron-right">
							    					<FontAwesomeIcon icon={faChevronRight} size="1x"/></span>
							    				</div></a>
							    				<a href="https://www2.azurtechnology.com/l/1040143/2023-12-19/3m588m/1040143/1702992405Um9GPu2K/Dual_Case_Study.pdf" target="_blank"><div className="navbar-link" onClick={this.closeDesktopDropdown}>Dual Case Study <span className="chevron-right">
							    					<FontAwesomeIcon icon={faChevronRight} size="1x"/></span>
							    				</div></a>
{/*								    			<Link to="/casestudies/movinx">
								    				<div className="navbar-link" onClick={this.closeDesktopDropdown}>Movinx <span className="chevron-right">
								    					<FontAwesomeIcon icon={faChevronRight} size="1x"/></span>
								    				</div>
								    			</Link>*/}
								    		</div>
						    			</div>}
						    		</div>
						    	</div>
						    	<Link to="/blog">
						    		<div className="navbar-link" onClick={this.scrollToTop}>Blog</div>
								</Link>
						    	<Link to="/aboutus">
						    		<div className="navbar-link" onClick={this.scrollToTop}>About Us</div>
						    	</Link>
						    	<Link to="/contactus">
						    		<div className="navbar-link" onClick={this.scrollToTop}>Contact Us</div>
						    	</Link>
						    	<Link to="/careers">
						    		<div className="navbar-link" onClick={this.scrollToTop}>Careers</div>
								</Link>
					    	</nav>
				    	</div>
				    	<div className="mobile-burger-menu">
				    		<div className="burger-icon" onClick={this.toggleMobileMenu}>
				    			{!this.state.showMobileMenu && <FontAwesomeIcon icon={faBars} size="2x"/>}
				    			{this.state.showMobileMenu && <FontAwesomeIcon icon={faXmark} size="3x"/>}
				    		</div>
				    		{this.state.showMobileMenu &&
				    			<div className="mobile-nav-menu">
					    			<nav>
								        {/*<Link to="/" onClick={this.offMobileMenu}>
								    		<div className="mobile-menu-item-wrapper"><div className="navbar-link">Home</div></div>
								    	</Link>*/}
								    	<div className="mobile-clickable-dropdown">
									    	<div className="mobile-menu-item-wrapper">
									    		<div className="navbar-link" onClick={e => this.toggleMobileDropdown('what')}>What we do
									    			{!this.state.showWhatDropdown && <span className="dropdown-arrow"><FontAwesomeIcon icon={faChevronDown} size="1x"/></span>}
									    			{this.state.showWhatDropdown && <span className="dropdown-arrow"><FontAwesomeIcon icon={faChevronUp} size="1x"/></span>}
									    		</div>
										    	<div className={this.state.showWhatDropdown ? "dropdown-mobile visible" : "dropdown-mobile"}>
									    			<Link to="/whatwedo" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>Overview <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
									    			<hr/>
									    			<Link to="/productcomponents" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>Product Components <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
									    			<Link to="/workingwithourteam" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>Working With Our Team <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
								    			</div>
							    			</div>
								    	</div>
								    	<div className="mobile-clickable-dropdown">
									    	<div className="mobile-menu-item-wrapper">
									    		<div className="navbar-link" onClick={e => this.toggleMobileDropdown('who')}>Who do we do it for?
									    			{!this.state.showWhoDropdown && <span className="dropdown-arrow"><FontAwesomeIcon icon={faChevronDown} size="1x"/></span>}
									    			{this.state.showWhoDropdown && <span className="dropdown-arrow"><FontAwesomeIcon icon={faChevronUp} size="1x"/></span>}
									    		</div>
										    	<div className={this.state.showWhoDropdown ? "dropdown-mobile visible" : "dropdown-mobile"}>
									    			<Link to="/mga" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>MGA <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
									    			<Link to="/brokers" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>Brokers <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
									    			<Link to="/carriers" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>Carriers <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
									    			<hr/>
									    			<Link to="/smarthomecasestudy" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>SmartHome Case Study <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>
{/*									    			<Link to="/casestudies/movinx" onClick={this.offMobileMenu}>
									    				<div className="navbar-link-dropdown-mobile" onClick={this.scrollToTop}>Movinx <span className="chevron-right"><FontAwesomeIcon icon={faChevronRight} size="1x"/></span></div>
									    			</Link>*/}
								    			</div>
							    			</div>
								    	</div>
								    	<Link to="/blog" onClick={this.offMobileMenu}>
						    				<div className="mobile-menu-item-wrapper"><div className="navbar-link">Blog</div></div>
										</Link>
								    	<Link to="/aboutus" onClick={this.offMobileMenu}>
								    		<div className="mobile-menu-item-wrapper"><div className="navbar-link">About Us</div></div>
								    	</Link>
								    	<Link to="/contactus" onClick={this.offMobileMenu}>
								    		<div className="mobile-menu-item-wrapper"><div className="navbar-link">Contact Us</div></div>
								    	</Link>
								    	<Link to="/careers" onClick={this.offMobileMenu}>
								    		<div className="mobile-menu-item-wrapper"><div className="navbar-link">Careers</div></div>
								    	</Link>
							    	</nav>
				    			</div>
				    		}
				    	</div>
		    		</div>
			    </header>
		    </div>
	    );
  	}
}

export default Navbar;