import React from 'react';
import axios from 'axios';
import withLazyLoad from './LazyLoad';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';

// import ascotBroker1 from '../assets/image/screenshots/azur-data-1.png';
// import ascotBroker2 from '../assets/image/screenshots/azur-data-2.png';
// import ascotBroker3 from '../assets/image/screenshots/azur-data-3.png';
// import ascotBroker4 from '../assets/image/screenshots/azur-data-4.png';

class CaseStudy extends React.Component {
	state = {
        data: null,
        currentCaseStudy: null,
        caseStudyNameMap: {
    		smarthome: 'Azur Group SmartHome',
    		ascot: 'Ascot Array',
    		movinx: 'Movinx'
    	},
    	backgroundImageUrl: null
    };

    componentDidUpdate() {
        this.fetchCurrentCaseStudy();
    }

    componentDidMount() {
    	this.fetchCurrentCaseStudy();

    	axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-city-4.jpg`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })
    }

    fetchCurrentCaseStudy = () => {
    	const elementToLazyLoad = document.getElementsByClassName('case-study-inner')[0];

    	if (this.state.currentCaseStudy !== this.props.caseStudyName) {
    		elementToLazyLoad.classList.remove('lazy-load');
    		elementToLazyLoad.classList.remove('lazy-load-active');

    		axios.get(`${process.env.REACT_APP_CMS_URL}/api/case-studies?filters[name][$eq]=${this.state.caseStudyNameMap[this.props.caseStudyName]}&populate=logo`)
	            .then(res => {
	                if (res.data.data.length) {
                        const caseStudyData = res.data.data[0];
                        const logoUrl = caseStudyData.attributes.logo.data ? caseStudyData.attributes.logo.data.attributes.url : null;

	                    this.setState({
	                    	currentCaseStudy: this.props.caseStudyName,
	                    	data: res.data.data[0],
                            logoUrl
	                    });
	                }

	                elementToLazyLoad.classList.add('lazy-load');
	                elementToLazyLoad.classList.add('lazy-load-active');
	            })

	        document.title = `Case Study - ${this.state.caseStudyNameMap[this.props.caseStudyName]}`;
    	}
    }

	render() {
    	return (
    		<div className="case-study-wrapper">
                { !this.state.data && <Loading spinnerColor="scooter" />}
	    		<div className="case-study-inner lazy-load">
	    			{ this.state.data && <div className="case-study-body-inner">
                            <div className="page-subheading">Case Study - {this.state.data.attributes.name}</div>
                            <div className="heading-wrapper">
                                <h1>{this.state.data.attributes.name}</h1>
                                {this.state.logoUrl && <img className="logo" src={this.state.logoUrl} />}
                            </div>
		    				<ReactMarkdown remarkPlugins={[[remarkGfm, {singleTilde: false}]]}>
		    					{this.state.data.attributes.body}
		    				</ReactMarkdown>
                            {this.state.data.attributes.pdfUrl &&
                                <a className="pdf-url-link" href={this.state.data.attributes.pdfUrl}>
                                    <button className="pdf-url-button">
                                        <div><FontAwesomeIcon icon={faFilePdf} size="2x" /></div>
                                        View the full award case study
                                    </button>
                                </a>
                            }
{/*                            <div className="img-wrapper">
                                <img className="brokerImage1" src={ascotBroker1} />
                                <img className="brokerImage2" src={ascotBroker2} />
                                <img className="brokerImage3" src={ascotBroker3} />
                                <img className="brokerImage4" src={ascotBroker4} />
                            </div>*/}
		    			</div>
		    		}
	    		</div>
	            {this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
	                <h3>Think we'll be a good fit for you?</h3>
	                <a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
	            </div>}
    		</div>
    	);
  	}
}

export default withLazyLoad(CaseStudy);