import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faArrowTrendUp, faFileContract, faQuoteLeft, faQuoteRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import withLazyLoad from './LazyLoad';
import pdfFileAscot from '../assets/pdf/celent-ascot-array.pdf';
import pdfFileSmart from '../assets/pdf/celent-smart-home.pdf';

class WhoCarriers extends React.Component {
	state = {
        backgroundImageUrl: null
    };

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_CMS_URL}/api/upload/files?filters[name][$eq]=background-buildings`)
            .then(res => {
                if (res.data.length) {
                    this.setState({...this.state, backgroundImageUrl: res.data[0].formats.large.url});
                }
            })

        document.title = 'Carriers - Azur Technology';
    }

	 render() {
	    return (
	    	<div className="who-wrapper">
	    		<div className="who-inner">
	    			<div className="page-subheading">Carriers</div>
	    			<h3 className="lazy-load"><strong>Carriers</strong> - Accelerate and Operate</h3>
	    			<h4 className="lazy-load">Accelerate the launch of your next product using our insurance-ready platform. Our technology is <strong>scalable</strong>, <strong>cloud-based</strong> and <strong>tailored</strong> to your customers and goals.</h4>
		            <div className="desktop-info-panels">
				    	<div className="info-panel lazy-load">
			                <div className="half-width-panel">
			                    <div className="small-square-1 background-olivine skew-left"></div>
			                    <FontAwesomeIcon icon={faArrowTrendUp} size="10x"/>
			                </div>
			                <div className="half-width-panel text-panel">
			                    <div>
									<h5>Operate your product once it’s launched with our dedicated team - ensuring you can continue to refine and innovate.</h5>
									<h5>We believe in creating a true partnership to deliver and an ongoing relationship to ensure your platform is maintained to the highest standard.</h5>
			                    </div>
			                </div>
			            </div>
		            </div>
		           	<div className="mobile-info-panels">
		            	<div className="full-width-panel fixed-height image-icon">
	                    	<div className="background-square small-square-3"></div>
	                    	<FontAwesomeIcon icon={faArrowTrendUp} size="6x"/>
	                	</div>
	                	<div className="full-width-panel">
	                    	<div className="text-panel-mobile">
	                        	<p className="text-paragraph-mobile">Operate your product once it’s launched with our dedicated team - ensuring you can continue to refine and innovate.</p>
	                        	<p className="text-paragraph-mobile">We believe in creating a true partnership to deliver and an ongoing relationship to ensure your platform is maintained to the highest standard.</p>
	                    	</div>
	                	</div>
		            </div>
{/*		            <div className="quote-wrapper lazy-load">
		            	<div className="mobile-quote-mark mobile-open-quote"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></div>
		                <h4><span className="quotation-mark"><FontAwesomeIcon icon={faQuoteLeft} size="3x"/></span>Insert another slightly longer quote here<span className="quotation-mark"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></span></h4>
		                <div className="mobile-quote-mark mobile-closed-quote"><FontAwesomeIcon icon={faQuoteRight} size="3x"/></div>
		                <h5>- Ascot</h5>
		            </div>*/}
            	</div>
        		{this.state.backgroundImageUrl && <div className="bottom-contact-banner" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.state.backgroundImageUrl})` }}>
                	<h3>Ready to be our next success story?</h3>
                	<a href="/contactus"><button>Get in touch <FontAwesomeIcon icon={faArrowRight} size="1x" /></button></a>
            	</div>}
	    	</div>
	    );
	}
}

export default withLazyLoad(WhoCarriers);